<template>
  <div class="row">
    <!-- First Column: Selected Dates and Change link -->
    <div class="col-md-6">
      <div class="date-range pb-2">Dates: <strong>{{ weeklyStartDate }} - {{ weeklyEndDate }}</strong> &nbsp; <a href="#" @click.prevent="showModal">Change</a></div>
    </div>
    <!-- Second Column: Extra Date Content if provided -->
    <div class="col-md-6" v-if="extraDateContent">
      <div class="extra-date-content">{{ extraDateContent }}</div>
    </div>
    <!-- Modal for changing date range -->
    <vue-final-modal v-model="isModalVisible">
      <AppWeeklyEmailDateRange :isReload="true" @date-selected="handleDateSelected" @close-modal="closeModal" />
    </vue-final-modal>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import AppWeeklyEmailDateRange from '@/views/AppWeeklyEmailDateRange.vue';

export default {
  name: 'WeeklyDateRangeDisplay',
  components: {
    VueFinalModal,
    AppWeeklyEmailDateRange,
  },
  props: {
    isReload: {
      type: Boolean,
      default: false
    },
    extraDateContent: {
      type: String,
      default: '' // Non-required prop for extra date content
    }
  },
  setup(props) {
    const isModalVisible = ref(false);
    const weeklyStartDate = ref('');
    const weeklyEndDate = ref('');

    const showModal = () => {
      isModalVisible.value = true;
    };

    const handleDateSelected = (startDate, endDate) => {
      weeklyStartDate.value = startDate;
      weeklyEndDate.value = endDate;
      sessionStorage.setItem('weeklyStartDate', startDate);
      sessionStorage.setItem('weeklyEndDate', endDate);

      //console.log("COMPONENT SDATE:",startDate);
      //console.log("COMPONENT EDATE:",endDate);
      isModalVisible.value = false;
      if (props.isReload) {
        window.location.reload();
      }
    };

    const closeModal = () => {
      weeklyStartDate.value = sessionStorage.getItem('weeklyStartDate') || '';
      weeklyEndDate.value = sessionStorage.getItem('weeklyEndDate') || '';
      isModalVisible.value = false;
    };

    onMounted(() => {
      weeklyStartDate.value = sessionStorage.getItem('weeklyStartDate') || '';
      weeklyEndDate.value = sessionStorage.getItem('weeklyEndDate') || '';
      //console.log("COMPONENT SDATE 2:",weeklyStartDate.value);
      //console.log("COMPONENT EDATE 2:",weeklyEndDate.value);
    });

    return {
      isModalVisible,
      weeklyStartDate,
      weeklyEndDate,
      showModal,
      handleDateSelected,
      closeModal,
    };
  }
};
</script>
