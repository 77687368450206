// src/utils/dateUtils.js

/**
 * Formats a date string to "YYYY-MM-DD".
 * @param {string} dateString - The ISO date string to format.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  
  /**
   * Formats a date string to "YYYY-MM-DD HH:MM:SS" (24-hour format).
   * @param {string} dateString - The ISO date string to format.
   * @returns {string} - The formatted date and time string.
   */
  export const formatDateTime = (dateString) => {
    if (!dateString) return 'N/A';
    if (dateString == null) return 'N/A';
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    // Convert to 12-hour format and get AM/PM
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM/PM
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
  };
  
  
  
  /**
   * Converts a date string to a more readable format (e.g., "25 Oct 2024, 18:48").
   * @param {string} dateString - The ISO date string to format.
   * @returns {string} - The formatted date and time in a human-readable format.
   */
  export const formatHumanReadable = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    return date.toLocaleString('en-GB', options);
  };
  