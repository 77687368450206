<template>
    <div v-if="isVisible" :class="alertClass" role="alert">
      <!-- Dynamically assign alert classes -->
      {{ message }}
      <i class="fas fa-times close-icon" @click="closeAlert"></i>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  
  export default {
    name: 'AlertMessage',
    props: {
      message: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        default: 'info', // Default to 'danger' if not specified
        
      },
    },
    setup(props) {
      const isVisible = ref(true);
  
      // Compute the alert class based on the 'type' prop
      const alertClass = computed(() => `alert alert-${props.type} alert-dismissible fade show`);
  
      const closeAlert = () => {
        isVisible.value = false;
      };
  
      return {
        isVisible,
        closeAlert,
        alertClass,
      };
    },
  };
  </script>
  
  <style scoped>
  .close-icon {
    cursor: pointer;
    float: right;
    font-size: 1.2rem;
    padding: 0.25rem;
  }
  
  /* You can customize additional styles here if needed */
  </style>  