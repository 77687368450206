<template>
  <div class="w-100">
    <BreadcrumbNav :crumbs="breadcrumbs" v-if="!loading" />
    <v-container>
      <div v-if="loading">Loading...</div>
      <v-row>
        <!-- User Information at the top -->
        <v-col cols="12">
          <v-card>
            <v-card-title>User Information</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-title>{{ userData.fullname }} ({{ userData.username }})</v-list-item-title>
                  <v-list-item-title>{{ userData.email }}</v-list-item-title>
                </v-list-item>

               
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Profile Edit Form -->
        <v-col cols="12">
          <!-- Display success message -->
          <v-alert v-if="successMessage" type="success" class="mb-3">
            {{ successMessage }}
          </v-alert>
          <VForm :validation-schema="schema" @submit="submitForm">
            <div class="mb-3">
              <label for="emailFromAddress" class="form-label">Email From Address</label>
              <Field name="emailFromAddress" id="emailFromAddress" type="email" class="form-control" v-model="formData.emailFromAddress" />
              <div class="text-danger">
                <ErrorMessage name="emailFromAddress" />
              </div>
            </div>

            <div class="pt-4">
              <h2 class="h4">Signature</h2>
            </div>

            <div class="mb-3">
              <label for="signatureName" class="form-label">Full Name</label>
              <Field name="signatureName" id="signatureName" type="text" class="form-control" v-model="formData.signatureName" />
              <div class="text-danger">
                <ErrorMessage name="signatureName" />
              </div>
            </div>

            <div class="mb-3">
              <label for="signatureTitle" class="form-label">Title</label>
              <Field name="signatureTitle" id="signatureTitle" type="text" class="form-control" v-model="formData.signatureTitle" />
              <div class="text-danger">
                <ErrorMessage name="signatureTitle" />
              </div>
            </div>

            <div class="mb-3">
              <label for="signatureUrl" class="form-label">URL</label>
              <Field name="signatureUrl" id="signatureUrl" type="url" class="form-control" v-model="formData.signatureUrl" />
              <div class="text-danger">
                <ErrorMessage name="signatureUrl" />
              </div>
            </div>

            <!-- Submit button with form submission -->
            <v-btn type="submit" color="primary">Save</v-btn>
          </VForm>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted, watchEffect } from 'vue';
import { useQuery, useMutation } from '@urql/vue';
import { Field, Form as VForm, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import { GET_USER_PROFILE, CREATE_OR_UPDATE_PROFILE, GET_USER_BY_USERNAME } from '../graphql/user_queries';


export default {
  name: 'UserProfile',
  components: {
    BreadcrumbNav,
    VForm,
    Field,
    ErrorMessage
  },
  setup() {
    const loading = ref(true);
    const breadcrumbs = ref([]);
    const successMessage = ref(""); // New state to hold the success message
    const { appContext } = getCurrentInstance();
    const username = appContext.config.globalProperties.$username;

    const userID = ref(0);

    const userData = ref({
      fullname: '',
      username: '',
      email: '',
      userId: '',
    });

    const formData = reactive({
      emailFromAddress: '', // Updated to camelCase
      signatureName: '',    // Updated to camelCase
      signatureTitle: '',   // Updated to camelCase
      signatureUrl: '',     // Updated to camelCase
    });

    // Fetch user by username
    const { data: UserByUsernameData, error: UserbyUsernameError } = useQuery({
      query: GET_USER_BY_USERNAME,
      variables: { username: username },
    });

    const get_profile_variables = ref({ userId: 0 });
    // Fetch user profile (initially with an empty userId)
    const { data: UserProfileData, error: UserProfileDataError, executeQuery: refetchUserProfile } = useQuery({
      query: GET_USER_PROFILE,
      variables: get_profile_variables.value ,
      pause: true, // Pause the query initially
    });
   

    // Watch user by username data and update the state
    watchEffect(() => {
      if (UserByUsernameData.value) {
        userData.value.fullname = `${UserByUsernameData.value.getUserByUsername.firstName} ${UserByUsernameData.value.getUserByUsername.lastName}`;
        userData.value.username = UserByUsernameData.value.getUserByUsername.username;
        userData.value.email = UserByUsernameData.value.getUserByUsername.email;
        userData.value.userId = UserByUsernameData.value.getUserByUsername.id;
        get_profile_variables.value.userId = UserByUsernameData.value.getUserByUsername.id;
        userID.value = UserByUsernameData.value.getUserByUsername.id; // for mutation
        refetchUserProfile();  // Execute the query
      }
      if (UserbyUsernameError.value) {
        //console.error('Error fetching user by username:', UserbyUsernameError.value);
      }
    });

    // Watch user profile data and update the formData
    watchEffect(() => {
      if (UserProfileData.value) {
        //console.log("USER ID:", userID.value);
        //console.log("USER PROFILE:", UserProfileData.value);
        if (UserProfileData.value.getUserProfile !== null) {
          formData.emailFromAddress = UserProfileData.value.getUserProfile.profile.emailFromAddress || '';
          formData.signatureName = UserProfileData.value.getUserProfile.profile.signatureName || '';
          formData.signatureTitle = UserProfileData.value.getUserProfile.profile.signatureTitle || '';
          formData.signatureUrl = UserProfileData.value.getUserProfile.profile.signatureUrl || '';
        } 
      }

      if (UserProfileDataError.value) {
        //console.error('Error fetching user profile:', UserProfileDataError.value);
      }
      loading.value = false; // Set loading to false after fetching data
    });

    // Form validation schema using Vee-Validate and Yup
    const schema = yup.object({
      emailFromAddress: yup
        .string()
        .required('Required')
        .email('Please enter a valid from email address'),
      signatureName: yup
        .string()
        .required('Required'),
      signatureTitle: yup
        .string()
        .required('Required'),
      signatureUrl: yup
        .string()
        .required('Required')
        .url('Please enter a valid URL'),
    });

    // Submit form function without wrapping handleSubmit
    const { executeMutation } = useMutation(CREATE_OR_UPDATE_PROFILE);
    const submitForm = (values) => {
      console.log("Form values:", values); // Debugging
      executeMutation({
        userId: userID.value, // Change this to actual user ID
        input: values,
      })
      .then((response) => {
        console.log('Form submitted successfully');
        successMessage.value = response.data.createOrUpdateProfile.message; // Update success message
      })
      .catch((err) => {
        console.error('Error during mutation:', err);
      });
    };

    // Set breadcrumbs on component mount
    onMounted(() => {
      breadcrumbs.value = [
        { text: 'Home', routerName: 'HomePage' },
        { text: 'User Profile', route: '' },
      ];
    });

    return {
      schema,
      userData,
      formData,
      submitForm,
      successMessage, // Bind successMessage to the template
      loading,
      BreadcrumbNav,
      breadcrumbs
    };
  },
};
</script>

