// tooltipService.js
export const initializeTooltips = () => {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((element) => {
      const tooltipText = element.getAttribute('title');
      if (tooltipText) {
        element.removeAttribute('title'); // Remove title to prevent browser default tooltip
  
        element.addEventListener('mouseenter', () => {
          const tooltip = document.createElement('div');
          tooltip.className = 'tooltip bs-tooltip-top'; // Bootstrap classes for styling
          tooltip.setAttribute('role', 'tooltip');
  
          const tooltipInner = document.createElement('div');
          tooltipInner.className = 'tooltip-inner';
          tooltipInner.innerText = tooltipText;
          tooltip.appendChild(tooltipInner);
  
          document.body.appendChild(tooltip);
  
          const rect = element.getBoundingClientRect();
          tooltip.style.position = 'absolute';
          tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2}px`;
          tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight - 8}px`;
  
          setTimeout(() => {
            tooltip.classList.add('show'); // Bootstrap "show" class for visibility
          }, 10);
  
          element.addEventListener('mouseleave', () => {
            tooltip.remove();
          });
        });
      }
    });
  };
  