<template>
    <div class="w-100">
      <BreadcrumbNav :crumbs="breadcrumbs" />
      <div class="data-list">
        <div v-if="loading"><div class="alert alert-info text-center" role="alert">Loading...</div></div>
        <div v-else-if="searchResults.length > 0">
          <div v-for="(firm, index) in searchResults" :key="firm.firmId" class="card mb-3 shadow-sm">
            <div class="card-header d-flex justify-content-between align-items-center" @click="toggleCollapse(index, firm.searchRecords)" role="button" aria-expanded="false"><strong>{{ firm.firmName }} ({{ firm.searchRecords.length }})</strong><i :class="collapsed[index] ? 'fas fa-plus' : 'fas fa-minus'" class="toggle-icon"></i></div>
            <div :id="'collapse-' + index" class="collapse" :class="{ show: !collapsed[index] }">
              <div v-for="record in firm.searchRecords" :key="record.id" class="list-group-item d-flex justify-content-between align-items-center">
                <span>{{ record.name }}</span>
                <button v-if="emailStatus[record.id]?.counter" class="btn btn-info" @click="viewStats(record.id)">View Stat</button>
                <button class="btn btn-secondary schedule-button" @click="CreateACampaign(record.id)"><span v-if="emailStatus[record.id]?.counter == 0">Create a Campaign</span><span v-else>Continue</span></button>
              </div>
            </div>
          </div>
        </div>
        <div v-else><div class="alert alert-warning text-center" role="alert"><strong>No records found!</strong> Please try adjusting your search.</div></div>
      </div>

      <VueFinalModal
        v-model="showViewStatmModal"
        classes="modal-container"
        content-class="modal-content"
      >
        <button class="modal-close btn-close" @click="showViewStatmModal = false"></button>
        <div v-if="selectedStat">
          <h3>Stats:</h3>
          <p><strong>Executives:</strong> {{ selectedStat.counter }}</p>
          <p><strong>Created:</strong> {{ formatDateTime(selectedStat.created) }}</p>
          <p><strong>User:</strong> {{ selectedStat.user }}</p>
        </div>
        <div v-else>
          <p>No stats available.</p>
        </div>
      </VueFinalModal>
    </div>
</template>
  
<script>
import { defineComponent, ref, watchEffect, onMounted } from 'vue';
import { useQuery } from '@urql/vue';
import { GET_BULK_EMAIL_STATUS_BY_SEARCH_LIST_IDS } from '../graphql/bulk_emails_to_execs';
import { APP_FIRMS_SEARCH_LISTS} from '../graphql/search_criteria_list';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import { useRouter } from 'vue-router';
import { VueFinalModal } from 'vue-final-modal';
import { formatDateTime } from '@/components/DateFormatter';

export default defineComponent({
  name: 'AppBulkEmailsExecs',
  components: {
    BreadcrumbNav,
    VueFinalModal,
  },
  setup() {
    const searchResults = ref([]);
    const loading = ref(true);
    const collapsed = ref([]);
    const emailStatus = ref({});
    const searchListIds = ref([]); // reactive variable for search list IDs
    const router = useRouter();
    const showViewStatmModal = ref(false);

    const breadcrumbs = [
      { text: 'Home', routerName: 'HomePage' },
      { text: 'Bulk Emails', routerName: '' },
    ];

    // Define query variables as a ref
    const variables = ref({
      search_list_ids: searchListIds.value,
    });

    // useQuery setup for fetching bulk email status
    const { data, executeQuery: fetchStatusData } = useQuery({
      query: GET_BULK_EMAIL_STATUS_BY_SEARCH_LIST_IDS,
      variables: variables.value,
      pause: true,
    });
    watchEffect(() => {
      if (searchListIds.value.length > 0) {
        variables.value.search_list_ids = searchListIds.value;
        fetchStatusData();
      }
    });

    watchEffect(() => {
      if (data.value) {
        emailStatus.value = data.value.bulkEmailExcGetStatusBySearchListIds.reduce((acc, status) => {
          if (status.searchListId) {
            acc[status.searchListId] = {
              counter: status.status.counter ?? 0, // Default to 0 if counter is undefined
              created: status.status.created ?? null, // Default to null if created is undefined
              user: status.status.user ?? null, // Default to null if user is undefined
            };
          } else {
            //console.warn("Encountered a record with undefined searchListId:", status);
          }
          return acc;
        }, {});

        console.log("STATUS:", emailStatus.value);
      }
    });

    // UseQuery setup for fetching firm search lists
    const { data: firmSearchData, error: firmSearchError, executeQuery: executeFirmSearchQuery } = useQuery({
      query: APP_FIRMS_SEARCH_LISTS,
      pause: true,
    });

    const loadFirmSearchLists = () => {
      loading.value = true;
      executeFirmSearchQuery();
    };

    // Watch firmSearchData for changes to populate searchResults
    watchEffect(() => {
      if (firmSearchData.value) {
        const firmsWithRecords = firmSearchData.value.appFirmsSearchLists
          .filter(firm => firm.searchRecords?.length)
          .sort((a, b) => a.firmName.localeCompare(b.firmName))
          .map(firm => ({
            ...firm,
            searchRecords: firm.searchRecords.sort((x, y) => x.name.localeCompare(y.name)),
          }));

        const firmsWithoutRecords = firmSearchData.value.appFirmsSearchLists
          .filter(firm => !firm.searchRecords?.length)
          .sort((a, b) => a.firmName.localeCompare(b.firmName));

        searchResults.value = [...firmsWithRecords, ...firmsWithoutRecords];
        collapsed.value = new Array(searchResults.value.length).fill(true);
        loading.value = false;
      }

      if (firmSearchError.value) {
        console.error('Error fetching data:', firmSearchError.value);
        loading.value = false;
      }
    });

    const toggleCollapse = (index, searchRecords) => {
      collapsed.value[index] = !collapsed.value[index];
      if (!collapsed.value[index]) {
        searchListIds.value = searchRecords.map(record => record.id);
      }
    };

    const CreateACampaign = (recordId) => {
        router.push({ name: 'AppBulkEmailsExecsList', params: { list_id: recordId } });
    };

    const selectedStat = ref(null);
    const viewStats = (searchListId) => {
      //console.log("searchListId:::",searchListId);
      selectedStat.value = emailStatus.value[searchListId] || null;
      showViewStatmModal.value = true;
    };

    onMounted(() => {
      loadFirmSearchLists();
    });

    return {
      searchResults,
      loading,
      collapsed,
      emailStatus,
      breadcrumbs,
      toggleCollapse,
      CreateACampaign,
      viewStats,
      showViewStatmModal,
      selectedStat,
      formatDateTime
    };
  },
});
</script>