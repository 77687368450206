<template>
  <div id="app">
    <router-view v-if="isDataReady"></router-view>
  </div>
</template>

<script>
import { useQuery } from '@urql/vue';
import { ref, watchEffect, getCurrentInstance } from 'vue';
import { GET_ALL_USERS } from '@/graphql/user_queries';
import { useStore } from 'vuex';

export default {
  name: 'App',
  setup() {
    const { proxy } = getCurrentInstance();
    const _keycloak = proxy.$keycloak;
    const store = useStore();
    const isDataReady = ref(false);

    // Reactive variable for all users
    const users = ref([]);

    // Automatically execute the query without pausing
    const { data: allUsersData, error } = useQuery({
      query: GET_ALL_USERS,
    });

    watchEffect(() => {
      if (allUsersData.value) {
        users.value = allUsersData.value.getAllUsers;
        store.dispatch('user/setUsers', users.value);
        const currentUser = users.value.find(
          (user) => user.username === _keycloak.tokenParsed.preferred_username
        );
        if (currentUser) {
          store.dispatch('user/setUserProfile', {
            userid: currentUser.id,
            isSuperuser: currentUser.isSuperuser,
          });
        } else {
          window.location.href = 'https://app.ceodiscovery.com';
        }
        isDataReady.value = true;
      }
      if (error.value) {
        console.error('Error fetching users:', error.value);
      }
    });

    return {
      isDataReady,
    };
  },
};
</script>
