<template>
  <div>
    <p>This is an in-house project designed to help our team streamline tasks and expedite work processes efficiently.</p>

    <div class="row">
      <!-- ZohoMail Authentication Card -->
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">ZohoMail Authentication</h5>
            <p class="card-text" v-if="isConnected">You are connected.</p>
            <p class="card-text" v-else>Checking ZohoMail authentication...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZohoMailAuthChecker from '@/components/ZohoMailAuthChecker'; // Ensure the path is correct
import { getCurrentInstance, ref, onMounted, watchEffect } from 'vue';

export default {
  name: 'HomePage',
  setup() {
    const isConnected = ref(false);

    const { appContext } = getCurrentInstance();
    const username = appContext.config.globalProperties.$username;

    onMounted(() => {
      document.getElementById('header-title').innerText = 'Welcome to Hal App Dashboard';
    });

    const { zohoAuthToken, tokenError } = ZohoMailAuthChecker(username, 'ZohoMail');
    watchEffect(() => {
      if (zohoAuthToken.value) {
        isConnected.value = true;
      }
      if (tokenError.value) {
        console.error("Authentication Error:", tokenError.value);
        isConnected.value = false;
      }
    });

    return {
      isConnected
    };
  }
};
</script>
