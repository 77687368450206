<template>
  <div>
    <WeeklyDateRangeDisplay :isReload="false" />
    <div v-for="firm in serverItems" :key="firm.id" class="row align-items-center mb-1 p-2 bg-light">
      <div class="col text-left">
        <router-link :to="{ name: 'EmailFirmSearchResults', params: { firmId: firm.id } }">
          {{ firm.name }} ({{ firm.totalCount }})
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watchEffect, getCurrentInstance } from 'vue';
import { useQuery } from '@urql/vue';
import { GET_MY_FIRMS } from '../graphql/firms_queries';
import WeeklyDateRangeDisplay from '../components/WeeklyDateRangeDisplay.vue';

export default defineComponent({
  name: "EmailsWeeklyEmails",
  components: {
    WeeklyDateRangeDisplay,
  },
  setup() {
    // Reactive references
    const serverItems = ref([]);
    const loading = ref(true);
    const totalItems = ref(0);
    const { appContext } = getCurrentInstance();
    const username = appContext.config.globalProperties.$username;

    // Set up the GraphQL query with pause: true and custom names
    const { data: firmData, error: firmError, executeQuery: executeFirmQuery } = useQuery({
      query: GET_MY_FIRMS,
      variables: { username: username }, // Replace 'naeemnn' with dynamic username if necessary
      pause: true,
    });

    // Function to fetch firm data explicitly
    const fetchFirmData = async () => {
      loading.value = true;
      await executeFirmQuery(); // Execute the query manually
    };

    // Watch for changes in firmData and firmError using watchEffect
    watchEffect(() => {
      if (firmData.value) {
        //console.log("firmData.value:", firmData.value);
        serverItems.value = firmData.value.appFirmSearchCounts;
        totalItems.value = serverItems.value.length;
        loading.value = false;
      }
      if (firmError.value) {
        console.error('Error fetching firm data:', firmError.value);
        loading.value = false;
      }
    });

    // Update header title
    const updateHeaderTitle = () => {
      const headerElement = document.getElementById('header-title');
      if (headerElement) {
        headerElement.innerText = 'Weekly Emails - Firms Lists';
      }
    };

    // Call fetchFirmData and updateHeaderTitle when the component is mounted
    onMounted(() => {
      updateHeaderTitle();
      fetchFirmData(); // Call the fetchFirmData function to execute the query
    });

    return {      
      serverItems,
      loading,
      totalItems,
    };
  },
});
</script>