<!-- src/views/NotFound.vue -->
<template>
  <div>
    
    
    <router-link class="" :to="{name: 'HomePage'}" >Back to Homepage</router-link>
  </div>
</template>
<script>
export default {
  name: 'AppProfiles',
};
</script>