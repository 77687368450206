<template>
  <div class="w-100">
    <!-- Breadcrumb navigation -->
    <BreadcrumbNav :crumbs="breadcrumbs" v-if="!table.isLoading" />

    <!-- Loading state -->
    <div v-if="table.isLoading">Loading...</div>

    <!-- No records found message -->
    <div v-if="!table.isLoading && table.rows.length === 0">No records found.</div>
    <div class="d-flex align-items-center">
      <input 
        type="text" 
        v-model="searchTerm" 
        placeholder="Search..." 
        class="form-control me-2" 
        style="max-width: 300px;"
      />
    </div>
    <!-- Table for weekly email list with pagination @row-clicked="onRowClicked" -->
    <VueTableLite
      :rows="table.rows"
      :columns="table.columns"
      :is-loading="table.isLoading"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      @do-search="doSearch"
      @is-finished="tableLoadingFinish"
      wrapper-class="w-100"
    />
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, ref, reactive, computed, watchEffect, watch, onMounted } from 'vue';
import { useQuery, useMutation } from '@urql/vue';
import { GET_WEEKLY_EMAILS_SAVED_LIST, DELETE_WEEKLY_EMAIL_SAVED_LIST } from '../graphql/weekly_emails_queries';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import VueTableLite from 'vue3-table-lite';
import { formatDateTime } from '@/components/DateFormatter';
import { setSessionDates } from '../mixins/CheckWeeklyDateSession';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useConfirmDialog } from '@/plugins/useConfirmDialog';

export default defineComponent({
  name: 'EmailsWeeklySavedList',
  components: {
    BreadcrumbNav,
    VueTableLite,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const userid = computed(() => store.getters['user/getUserId']);
    const isSuperuser = computed(() => store.getters['user/isUserSuperuser']);
    
    const breadcrumbs = ref([]);
    const list_save_status = ref(route.params.status || 'Draft');
    
    const { appContext } = getCurrentInstance();
    const appProfileURL = appContext.config.globalProperties.$AppProfileURL;
    const appFirmURL = appContext.config.globalProperties.$AppFirmURL;

    const searchTerm = ref('');

    const table = reactive({
      isLoading: false,
      rows: [],
      columns: [
        { label: 'ID', field: 'id', sortable: true },
        { label: 'Name', field: 'listname', sortable: true },
        { label: 'Firm', field: 'firmId', sortable: false, display: (row) => `<a href="${appFirmURL}${row.firmId}" target="_blank">Firm</a>` },
        { label: 'Professional', field: 'professionalId', sortable: false, display: (row) => `<a href="${appProfileURL}${row.professionalId}" target="_blank">Professional</a>` },
        { label: 'Range', field: 'dateRange', sortable: false },
        { label: 'Status', field: 'status', sortable: false },
        { label: 'By', field: 'savedBy', sortable: true },
        { label: 'Created', field: 'created', sortable: true, display: (row) => formatDateTime(row.created) },
        { label: 'Updated', field: 'statusDate', sortable: true, display: (row) => formatDateTime(row.statusDate) },
        {
          label: 'Actions',
          field: 'actions',
          display: (row) => `<button class="btn btn-info view-button" data-date-range="${row.dateRange}" data-profile-id="${row.professionalId}" data-list-id="${row.id}" data-status="${row.status}">View</button> <button class="btn btn-danger delete-button" data-id="${row.id}">Delete</button>`
        }
      ],
      totalRecordCount: 0,
      sortable: {
        order: 'id',
        sort: 'asc',
      },
    });
    const queryVariables = ref({
      userId: userid.value,
      isSuperuser: isSuperuser.value ? 1 : 0,
      status: list_save_status.value,
      limit: 10,
      offset: 0,
      searchTerm: '',
    });
    const confirmDialog = useConfirmDialog();

    const { data: weeklyEmailListData, error: weeklyEmailListError, executeQuery } = useQuery({
      query: GET_WEEKLY_EMAILS_SAVED_LIST,
      variables: queryVariables.value,
      pause: true,
    });

    watchEffect(() => {
      if (userid.value && isSuperuser.value !== undefined && list_save_status.value) {
        queryVariables.value.userId = userid.value;
        queryVariables.value.isSuperuser = isSuperuser.value ? 1 : 0;
        queryVariables.value.status = list_save_status.value;
        executeQuery();
      }
    });

    watchEffect(() => {
      if (weeklyEmailListError.value) {
        console.error('GraphQL Error:', weeklyEmailListError.value);
        table.isLoading = false;
      }
      if (weeklyEmailListData.value) {
        const { records, totalCount } = weeklyEmailListData.value.listWeeklyEmailsSavedLists;
        table.rows = records;
        table.totalRecordCount = totalCount;
      }
      table.isLoading = false;
    });

    const doSearch = (offset, limit, order, sort) => {
      //console.log("DSDSDS JJJJ::");
      table.isLoading = true;
      queryVariables.value.offset = offset;
      queryVariables.value.limit = limit;
      queryVariables.value.sort = sort;
      queryVariables.value.order = order;
      queryVariables.value.searchTerm = searchTerm.value;
      executeQuery().then(() => {
        table.isLoading = false; // Reset loading state after fetching
      });
    };
    watch(
        () => searchTerm.value,
        () => {
          //console.log("Search term updated:", newTerm);
          doSearch(0, table.pageSize, table.sortable.order, table.sortable.sort);
        }
      );

    const tableLoadingFinish = () => {
      table.isLoading = false;
    };

    const onRowClicked = (row) => {
      viewRow(row.dateRange, row.professionalId, row.id, row.status);
    };
    
    const viewRow = (dateRange, profileId, listId = null, status = null) => {
      const [startDate, endDate] = dateRange.split(' - ');
      if (startDate && endDate) {
        setSessionDates(startDate, endDate);
      }
      appContext.config.globalProperties.$router.push({
        name: 'EmailsWeeklySearchExecs',
        params: {
          profile_id: profileId,
          list_id: listId || undefined,
          status: status || undefined,
        },
      });
    };

    const { executeMutation: executeDelete } = useMutation(DELETE_WEEKLY_EMAIL_SAVED_LIST);
    const deleteRecord = async (id) => {
      try {
        const variables = { id, userId: userid.value };
        const result = await executeDelete(variables);
        if (result.data?.deleteWeeklyEmailSavedList.success) {
         //alert('Record successfully deleted.');
          window.location.reload(); // Refresh the whole page
          // Call doSearch to reload the table with the updated data
          //doSearch(queryVariables.value.offset, queryVariables.value.limit, table.sortable.order, table.sortable.sort);
          
        } else {
          alert(result.data?.deleteWeeklyEmailSavedList.message || 'Failed to delete the record.');
        }
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('An error occurred while deleting the record.');
      }
    };


    onMounted(() => {
      breadcrumbs.value = [
        { text: 'Home', routerName: 'HomePage' },
        { text: 'Saved List', route: '' },
      ];
      watchEffect(() => {
        list_save_status.value = route.params.status || 'Draft';
        if (list_save_status.value) {
          const headerTitle = document.getElementById('header-title');
          if (headerTitle) {
            headerTitle.innerText = `Weekly Emails - Saved List - ${list_save_status.value}`;
          }
        }
      });

      document.addEventListener('click', async (event) => {
        if (event.target.classList.contains('view-button')) {
          const dateRange = event.target.getAttribute('data-date-range');
          const profileId = event.target.getAttribute('data-profile-id');
          const listId = event.target.getAttribute('data-list-id');
          const status = event.target.getAttribute('data-status');
          viewRow(dateRange, profileId, listId, status);
        }//end view button

        if (event.target.classList.contains('delete-button')) {
          const id = event.target.getAttribute('data-id');
          const confirmed = await confirmDialog(`Are you sure you want to delete this campaign? This will remove all executives and stats.`);
          if (!confirmed) return;
            deleteRecord(id);
        }//end delete button

      });

    });

    return {
      table,
      doSearch,
      tableLoadingFinish,
      onRowClicked,
      viewRow,
      breadcrumbs,
      searchTerm
    };
  },
});
</script>
