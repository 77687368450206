<!-- src/views/NotFound.vue -->
<template>
  <div>
    <router-link class="" :to="{name: 'HomePage'}" ><img src="../assets/images/CEODiscovery-logo.png" alt="Logo" class="img-fluid"></router-link>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <router-link class="" :to="{name: 'HomePage'}" >Back to Homepage</router-link>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
};
</script>