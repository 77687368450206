import gql from 'graphql-tag';

export const BULK_EMAIL_EXC_SAVE_UPDATE_EMAIL_TEMPLATE = gql`
  mutation BulkEmailExcSaveUpdateEmailTemplate(
    $firmId: String!,
    $searchListId: String!,
    $userId: Int!,
    $emailSubject: String!,
    $emailBody: String!,
    $selectedHeader: String!
  ) {
    bulkEmailExcSaveUpdateEmailTemplate(
      firmId: $firmId,
      searchListId: $searchListId,
      userId: $userId,
      emailSubject: $emailSubject,
      emailBody: $emailBody,
      selectedHeader: $selectedHeader
    ) {
      success
      errors
    }
  }
`;

export const GET_BULK_EMAIL_STATUS_BY_SEARCH_LIST_IDS = gql`
  query getBulkEmailStatusBySearchListIds($search_list_ids: [String!]!) {
    bulkEmailExcGetStatusBySearchListIds(searchListIds: $search_list_ids) {
      searchListId
      status {
        counter
        created
        user
      }
    }
  }
`;

export const GET_BULK_EMAIL_EXC_GET_ALL_EXECS_SEARCH_LIST = gql`
  query get_bulk_email_exc_get_all_execs_search_list(
    $search_list_id: String,
    $campaign_id: Int,
    $user_id: Int!, 
    $page: Int, 
    $offset: Int, 
    $limit: Int, 
    $order: String, 
    $sort: String,
    $searchTerm: String,
    $archive: Boolean = false  # Default archive to false
  ) {
    getBulkEmailExcGetAllExecsSearchList(
      searchListId: $search_list_id,
      campaignId: $campaign_id,
      userId: $user_id, 
      page: $page, 
      offset: $offset, 
      limit: $limit, 
      order: $order, 
      sort: $sort,
      searchTerm: $searchTerm,
      archive: $archive
    ) {
      dbStatus
      items {
        execId
        execEmail
        created
        execFirstName
        execTitle
        execLastName
        id
        emailStatus
        emailStatusDesc
        execCompany
        updated
        user
      }
      firmName
      firmId
      page
      pageSize
      totalCount
      searchListId
      searchListName
      campaignId
      campaignName
      campaignActive
    }
  }
`;

export const BULK_EMAIL_EXC_GET_EMAIL_TEMPLATE = gql`
  query bulk_email_exc_get_email_template($searchListId: String!) {
    bulkEmailExcGetEmailTemplate(searchListId: $searchListId) {
      created
      emailBody
      emailSubject
      id
      selectedHeader
      updated
      user
      emailFromAddress
      signatureName
      signatureTitle
      signatureUrl
    }
  }
`;

export const BULK_EMAIL_EXC_INITIALIZE_EMAIL_SCHEDULER = gql`
  mutation BulkEmailExcInitializeEmailScheduler(
    $firmId: String!,
    $searchListId: String!,
    $userId: Int!,
    $campaignId: Int!,
    $execs: [ExecInput!]!
  ) {
    bulkEmailExcInitializeEmailScheduler(
      firmId: $firmId,
      searchListId: $searchListId,
      userId: $userId,
      campaignId: $campaignId,
      execs: $execs
    ) {
      errors
      success
      lastInsertIds {
        id
      }
    }
  }
`;

export const GET_BULK_EMAIL_CAMPAIGN_BY_ID = gql`
  query getBulkEmailExcCampaignById($campaignId: Int!, $archive: Boolean = false) {
    getBulkEmailExcCampaignById(campaignId: $campaignId, archive: $archive) {
      id
      name
      searchListId
      firmId
      active
      fromUser
      created
      ended
      totalExecutives
      noActionYet
      sent
      error
      userNotes
      adminNotes
      emailTemplate
    }
  }
`;

export const COUNT_BULK_EMAIL_CAMPAIGNS_BY_STATUS = gql`
  query CountBulkEmailCampaignsByStatus(
    $statuses: [String!]!
  ) {
    countBulkEmailCampaignsByStatus(
      statuses: $statuses
    ) {
      count
      status
    }
  }
`;

export const GET_BULK_EMAIL_CAMPAIGNS_BY_STATUS = gql`
  query GetBulkEmailCampaignsByStatus(
    $status: String!,
    $page: Int,
    $offset: Int,
    $limit: Int,
    $order: String,
    $sort: String,
    $searchTerm: String
  ) {
    getBulkEmailCampaignsByStatus(
      status: $status,
      page: $page,
      offset: $offset,
      limit: $limit,
      order: $order,
      sort: $sort,
      searchTerm: $searchTerm
    ) {
      items {
        id
        name
        searchListId
        active
        userId
        fromUser
        created
        ended
      }
      totalCount
      page
      pageSize
    }
  }
`;

export const UPDATE_BULK_EMAILS_CAMPAIGN_NOTES = gql`
  mutation UpdateBulkEmailsCampaignNotes($id: Int!, $userNotes: String, $adminNotes: String, $status: String) {
    updateBulkEmailsCampaignNotes(id: $id, userNotes: $userNotes, adminNotes: $adminNotes, status: $status)
  }
`;

export const DELETE_BULK_EMAIL_CAMPAIGN = gql`
  mutation DeleteBulkEmailCampaign($id: Int!) {
    deleteBulkEmailCampaign(id: $id)
  }
`;

export const DELETE_BULK_EMAIL_EXECUTIVE = gql`
  mutation DeleteBulkEmailExecutive($execId: Int!) {
    deleteBulkEmailExecutive(execId: $execId)
  }
`;