<template>
  <VueFinalModal v-model="showEmailTemplateModal" content-class="custom-modal-content w-75">
    <div class="position-relative"><button class="btn-close position-absolute top-0 end-0" @click="closeModal"></button></div>
    <h3>Email Template - {{ page_header_text }}</h3>
    <AlertMessage v-if="showAlert" :message="alertMessage" :type="alertType" />
    <!-- Toggle Button to switch between Edit and Preview mode -->
    <button class="btn btn-info mb-3" @click="togglePreview">{{ isPreview ? "Edit Email" : "Preview Email" }}</button>
    &nbsp; <button type="button" class="btn btn-secondary mb-3" @click="sendEmailContent">Send Test Email</button>
    <!-- Conditional to toggle between preview and edit mode -->
    <div v-if="isPreview">
      <p><strong>Subject:</strong> {{ emailTemplateData.email_subject }}</p>
      <p><strong>Body:</strong></p>
      <div class="card bg-light p-5 pb-10">
        <div><p>{{ formattedHeader }}:</p></div>
        <div v-html="emailTemplateData.email_body" ></div>
        <div>
          <p>
            {{ emailTemplateData.signature_name }}<br/>
            {{ emailTemplateData.signature_title }}<br/>
            <a :href="emailTemplateData.signature_url" target="_blank">CEODiscovery.com</a>
          </p></div>
      </div>
    </div>

    <form v-else @submit.prevent="saveEmailTemplate">
      <!-- From Email Dropdown -->
      <div class="mb-1">
        <label class="form-label">From Email</label>
        <select class="form-control" v-model="fromEmailUserId" required>
          <option disabled value="">Select a user</option>
          <option v-for="user in allUsers" :key="user.id" :value="user.id">
            {{ user.firstName }} {{ user.lastName }} - {{ user.email }}
          </option>
        </select>
      </div>
      <div class="mb-1">
        <label class="form-label">Email Subject</label>
        <input type="text" class="form-control" v-model="emailTemplateData.email_subject" required />
      </div>
      <div class="mb-1">
        <label class="form-label">Header Type</label>
        <select class="form-control me-2" v-model="type">
          <option value="first_name">First Name</option>
          <option value="last_name">Last Name</option>
          <option value="full_name">Full Name</option>
          <option value="company_name">Company Name</option>
        </select>
      </div>
      <div class="mb-1">
        <label class="form-label">Email Body</label>
        <QuillEditor
          v-model:content="emailTemplateData.email_body"
          content-type="html"
          :toolbar="[
            [{'header': [1, 2, false] }],
            ['bold', 'italic'],
            [{'list': 'ordered' }, {'list': 'bullet'}],
            ['link'],
          ]"
          style="min-height: 300px"
        />
      </div>
      <button type="submit" class="btn btn-primary">Save Template</button>
    </form>
  </VueFinalModal>
</template>

<script>
import { ref, watchEffect, computed } from 'vue';
import { useQuery, useMutation } from '@urql/vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { BULK_EMAIL_EXC_GET_EMAIL_TEMPLATE, BULK_EMAIL_EXC_SAVE_UPDATE_EMAIL_TEMPLATE } from '../graphql/bulk_emails_to_execs';
import { VueFinalModal } from 'vue-final-modal';
import { useStore } from 'vuex';
import { fetchAuthToken, sendEmail } from '../components/ZohoAuthEmailService';
import AlertMessage from '@/components/AlertMessage.vue';

export default {
  name: 'BulkEmailExecTemplateModal',
  components: {
    VueFinalModal,
    QuillEditor,
    AlertMessage
  },
  props: {
    showModal: Boolean,
    searchListId: String,
    pageTitle: String,
    firmId: String,
    userId: Number,
  },
  emits: ['update:showModal', 'dbStatusUpdate'],
  setup(props, { emit }) {
    const store = useStore();
    const showEmailTemplateModal = ref(props.showModal);
    const db_status = ref(false);
    const isPreview = ref(false); // Tracks whether preview mode is active
    const type = ref('first_name');
    const page_header_text = ref(props.pageTitle);
    const fromEmailUserId = ref(props.userId || '');

    const emailTemplateData = ref({
      email_subject: '',
      email_body: '',
      selected_header: '',
      signature_name: '',
      signature_title: '',
      signature_url: '',
      email_from_address: '',
    });
    const allUsers = computed(() => store.getters['user/getUsers']);
    const current_user = computed(() => allUsers.value.find(u => u.id == props.userId));

    //console.log("current_user:",current_user.value);
    const email_Template_Variables = ref({ searchListId: props.searchListId });
    const { data: templateData, executeQuery: fetchEmailTemplate } = useQuery({
      query: BULK_EMAIL_EXC_GET_EMAIL_TEMPLATE,
      variables: email_Template_Variables.value,
      pause: true,
    });

    watchEffect(() => {
      if (props.searchListId) {
        showEmailTemplateModal.value = props.showModal;
        email_Template_Variables.value.searchListId = props.searchListId;
        page_header_text.value = props.pageTitle;
        fetchEmailTemplate();
      }
    });

    watchEffect(() => {
      if (templateData.value?.bulkEmailExcGetEmailTemplate) {
        const template = templateData.value.bulkEmailExcGetEmailTemplate;
        emailTemplateData.value = {
          email_subject: template.emailSubject,
          email_body: template.emailBody,
          selected_header: template.selectedHeader,
          signature_name: template.signatureName,
          signature_title: template.signatureTitle,
          signature_url: template.signatureUrl,
          email_from_address: template.emailFromAddress,
        };
        fromEmailUserId.value = template.user || props.userId; 
        db_status.value = true;
        type.value = template.selectedHeader || 'first_name';
      } else {
        db_status.value = false;
      }
      localStorage.setItem('db_status', JSON.stringify(db_status.value));
    });

    watchEffect(() => {
      emit('dbStatusUpdate', db_status.value);
    });

    const formattedHeader = computed(() => {
      return `${type.value}`;
    });

    const togglePreview = () => {
      isPreview.value = !isPreview.value;
    };

    const closeModal = () => {
      emit('update:showModal', false);
      emit('dbStatusUpdate', db_status.value);
    };

    const showAlert = ref(false);
    const alertMessage = ref('');
    const alertType = ref('danger');
    const { executeMutation: saveEmailTemplateMutation } = useMutation(BULK_EMAIL_EXC_SAVE_UPDATE_EMAIL_TEMPLATE);
    const saveEmailTemplate = () => {
      saveEmailTemplateMutation({
        firmId: props.firmId,
        searchListId: props.searchListId,
        userId: parseInt(fromEmailUserId.value, 10),
        emailSubject: emailTemplateData.value.email_subject,
        emailBody: emailTemplateData.value.email_body,
        selectedHeader: type.value,
      })
        .then((response) => {
          if (response.data?.bulkEmailExcSaveUpdateEmailTemplate.success) {
            alertMessage.value = 'Template saved successfully!';
            alertType.value = 'success';
            db_status.value = true;
            showAlert.value = true;
            fetchEmailTemplate({ requestPolicy: 'network-only' });
            setTimeout(() => {
              showAlert.value = false;
            }, 3000);
          } else {
            alertMessage.value = 'Failed to save template!';
            alertType.value = 'danger';
            showAlert.value = true;
          }
        })
        .catch((err) => {
          alertMessage.value = 'Error saving template!';
          alertType.value = 'danger';
          showAlert.value = true;
          console.error('Error saving template:', err);
        });
    };

    
    const sendEmailContent = async () => {
      const confirmation = window.confirm('Are you sure you want to send this email?');
      if (!confirmation) return;

      const content = computed(() => `
        <p>${formattedHeader.value}:</p>
          ${emailTemplateData.value.email_body}
        <p>
          ${emailTemplateData.value.signature_name}<br/>
          ${emailTemplateData.value.signature_title}<br/>
          <a href="${emailTemplateData.value.signature_url}" target="_blank">CEODiscovery.com</a>
        </p>
      `);
      try {
        console.log("fromEmailUserId.value:",fromEmailUserId.value);
        const fromAddress = `${emailTemplateData.value.signature_name} <${emailTemplateData.value.email_from_address}>`;
        const user_to_full_name = computed(() => `${current_user.value.firstName} ${current_user.value.lastName}`);
        //console.log("user_to_full_name:",user_to_full_name.value);
        const toAddress = computed(() => `${user_to_full_name.value} <${current_user.value.email}>`);
        const bccAddress = '';
        
        const emailSubject = emailTemplateData.value.email_subject || '';
        const detailedConfirmation = window.confirm(`
          Please confirm the email details:\n
          From: ${fromAddress}\n
          To: ${toAddress.value}\n
          Subject: ${emailSubject}\n\n
          Are you sure you want to send this email?
        `);
        if (!detailedConfirmation) return;

        const { accessToken, accountId } = await fetchAuthToken(parseInt(fromEmailUserId.value, 10));
        const result = await sendEmail(content.value, fromAddress, toAddress.value, accessToken, accountId, emailSubject, bccAddress);
        console.log('Email sent successfully:', result);
        
      } catch (error) {
        console.error('Failed to send email:', error);
      }
    };

    return {
      showEmailTemplateModal,
      emailTemplateData,
      type,
      closeModal,
      saveEmailTemplate,
      page_header_text,
      db_status,
      formattedHeader,
      togglePreview,
      isPreview,
      allUsers,
      fromEmailUserId,
      sendEmailContent,
      current_user,
      alertMessage,
      alertType,
      showAlert
    };
  },
};
</script>

<style scoped>
.custom-modal-content {
  padding: 20px;
}
</style>
