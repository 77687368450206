export default {
  namespaced: true,
  state: {
    userid: null,
    isSuperuser: false,
    users: [],
  },
  mutations: {
    setUserId(state, userid) {
      state.userid = userid;
    },
    setIsSuperuser(state, isSuperuser) {
      state.isSuperuser = isSuperuser;
    },
    setUsers(state, users) {
      state.users = users;
    },
    clearUserData(state) {
      state.userid = null;
      state.isSuperuser = false;
      state.users = [];
    }
  },
  actions: {
    setUserProfile({ commit }, { userid, isSuperuser }) {
      commit('setUserId', userid);
      commit('setIsSuperuser', isSuperuser);
    },
    setUsers({ commit }, users) {
      commit('setUsers', users);
    },
    clearUserData({ commit }) {
      commit('clearUserData');
    }
  },
  getters: {
    getUserId: (state) => state.userid,
    isUserSuperuser: (state) => state.isSuperuser,
    getUsers: (state) => state.users,
    getUsernameById: (state) => (userId) => {
      const user = state.users.find((user) => user.id === userId);
      return user ? user.username : null;
    },
  }
};