<template>
  <div>
    <div>Select Date Range</div>
    <date-picker v-model="value" range format="yyyy-MM-dd" :default-value="defaultDateRange" @change="handleDateChange">
      <template v-slot:content>
        <calendar-panel :value="innerValue" :get-classes="getClasses" @pick="handleSelect"></calendar-panel>
      </template>
    </date-picker>
    <div v-if="value.length === 2" class="selected-dates">
      <p>Selected Date Range: {{ formatDate(value[0]) }} - {{ formatDate(value[1]) }}</p>
    </div>
    <button @click="goToNextPage">Submit</button>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import { format, subDays, parseISO } from 'date-fns';
import { useQuery } from '@urql/vue';
import { INITIALIZE_FIRM_SEARCH_LIST_RESULTS } from '../graphql/firms_queries';

const { Calendar: CalendarPanel } = DatePicker;

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

export default {
  name: 'AppWeeklyEmailDateRange',
  components: {
    DatePicker,
    CalendarPanel,
  },
  props: {
    isReload: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const router = useRouter(); // Use Vue Router in the Composition API
    const route = useRoute();   // Use Vue Route in the Composition API

    const today = new Date();
    const lastWeek = subDays(today, 7);

    const formattedToday = format(today, 'yyyy-MM-dd');
    const formattedLastWeek = format(lastWeek, 'yyyy-MM-dd');

    const value = ref([formattedLastWeek, formattedToday]);
    const innerValue = ref([formattedLastWeek, formattedToday]);
    const defaultDateRange = ref([formattedLastWeek, formattedToday]);

    const { appContext } = getCurrentInstance();
    const username = appContext.config.globalProperties.$username;

    const initializeQuery = useQuery({
      query: INITIALIZE_FIRM_SEARCH_LIST_RESULTS,
      variables: { username: username },
      pause: true,
    });

    const getClasses = (cellDate, currentDates, classes) => {
      if (
        !/disabled|active|not-current-month/.test(classes) &&
        currentDates.length === 2 &&
        cellDate.getTime() > currentDates[0].getTime() &&
        cellDate.getTime() < currentDates[1].getTime()
      ) {
        return "in-range";
      }
      return "";
    };

    const handleSelect = (date) => {
      console.log("dates SELEC:::",date);
      const [startValue, endValue] = innerValue.value;
      if (isValidDate(startValue) && !isValidDate(endValue)) {
        if (startValue.getTime() > date.getTime()) {
          innerValue.value = [date, startValue];
        } else {
          innerValue.value = [startValue, date];
        }
        value.value = innerValue.value.map(date => format(date, 'yyyy-MM-dd'));
      } else {
        innerValue.value = [date, new Date(NaN)];
      }
    };

    const handleDateChange = (dates) => {
      console.log("dates:::",dates);
    };

    const formatDate = (date) => {
      //return format(date, 'yyyy-MM-dd');
      return date;
    };

    const initializeFirmSearchListResults = async () => {
      const { data, error } = await initializeQuery.executeQuery();
        if (data.value) {
          const message = data.value.initializeFirmSearchListResults;
          if (message === "Success: Records updated.") {
            console.log("Initialization successful");
          } else {
            console.error('Initialization failed:', message);
          }
        }
      if (error.value) {
        console.error('Error during initialization:', error);
      }
    };

    const goToNextPage = async () => {
      sessionStorage.setItem('weeklyStartDate', formatDate(value.value[0]));
      sessionStorage.setItem('weeklyEndDate', formatDate(value.value[1]));

      console.log("Before SUBMIT 1::",value.value[0]);
      console.log("Before SUBMIT 2::",value.value[1]);
      //return false;
      
      await initializeFirmSearchListResults();
      if (route && route.name === 'AppWeeklyEmailDateRange') {
        router.push({name: 'EmailsWeeklyEmails'});
      } else {
        emit('close-modal');
        if (props.isReload) {
          window.location.reload();
        }
      }
    };

    onMounted(() => {
      if (sessionStorage.getItem('weeklyStartDate') && sessionStorage.getItem('weeklyEndDate')) {
        const weeklyStartDate = sessionStorage.getItem('weeklyStartDate');
        const weeklyEndDate = sessionStorage.getItem('weeklyEndDate');
        //const formattedStartDate = format(weeklyStartDate, 'yyyy-MM-dd');
        //const formattedEndDate = format(weeklyEndDate, 'yyyy-MM-dd');
        value.value = [
          weeklyStartDate,
          weeklyEndDate
        ];
        innerValue.value = [
          parseISO(weeklyStartDate),
          parseISO(weeklyEndDate)
        ];
      }
    });

    return {
      value,
      innerValue,
      defaultDateRange,
      getClasses,
      handleSelect,
      handleDateChange,
      goToNextPage,
      formatDate,
      username
    };
  },
};
</script>


<style scoped>
.selected-dates {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>