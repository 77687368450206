import { h, render } from 'vue';
import ConfirmDialog from '../components/ConfirmDialog.vue';  // Adjust the path if needed

export function useConfirmDialog() {
  const mountNode = document.createElement('div');
  document.body.appendChild(mountNode);

  return (message) => {
    return new Promise((resolve) => {
      const vnode = h(ConfirmDialog, {
        message,
        isVisible: true,
        onConfirm: () => {
          render(null, mountNode);  // Unmount component
          resolve(true);  // Resolve with true for confirm
        },
        onCancel: () => {
          render(null, mountNode);  // Unmount component
          resolve(false);  // Resolve with false for cancel
        },
        'onUpdate:isVisible': (value) => {
          if (!value) render(null, mountNode);  // Unmount component if closed
        }
      });
      render(vnode, mountNode);
    });
  };
}
