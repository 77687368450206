import Keycloak from 'keycloak-js';
import { keycloak_config } from '@/config/keycloak-config.js';

const _keycloak = new Keycloak(keycloak_config);

const Plugin = {
  install(app) {
    app.config.globalProperties.$keycloak = _keycloak;
  },
};

export { _keycloak };
export default Plugin;