<template>
  <div class="w-100">
    <!-- Loading state -->
    <div v-if="loading">Loading...</div>
    <BreadcrumbNav :crumbs="breadcrumbs" v-if="!loading" />

    <!-- Zoho Authentication -->
    <div v-if="!zohoAuthTokenExists && !loading">
      <p><a :href="zohoAuthURL" class="btn btn-primary">Login to Zoho</a></p>
    </div>
    <div v-else-if="!loading">
      <p>You are already connected!</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watchEffect, getCurrentInstance } from 'vue';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import ZohoMailAuthChecker from '@/components/ZohoMailAuthChecker';
import { useQuery } from '@urql/vue';
import { GET_ZOHO_AUTH_URL } from '../graphql/zoho_auth_queries';

export default defineComponent({
  name: 'ZohoZohoAuth',
  components: { BreadcrumbNav },
  setup() {
    const loading = ref(true);
    const breadcrumbs = ref([]);
    const zohoAuthURL = ref('');
    const zohoAuthTokenExists = ref(false);
    const { appContext } = getCurrentInstance();
    const username = appContext.config.globalProperties.$username;

    // Call the ZohoMailAuthChecker service
    const { zohoAuthToken } = ZohoMailAuthChecker(username, 'ZohoMail');

    // Query for Zoho Auth URL
    const { data: urlData, error: urlError } = useQuery({
      query: GET_ZOHO_AUTH_URL,
      variables: { tokenType: 'ZohoMail' },
    });

    // Watch for token and auth URL data changes
    watchEffect(() => {
      loading.value = true;

      // If token exists, mark as connected
      if (zohoAuthToken.value) {
        zohoAuthTokenExists.value = true;
      }

      // Set Zoho Auth URL
      if (urlData.value) {
        //console.log("UUUUDRL::",urlData.value.zohoAuthUrl);
        zohoAuthURL.value = urlData.value.zohoAuthUrl.url;
      }

      // Log error if there is any issue with fetching Zoho auth URL
      if (urlError.value) {
        console.error('Error fetching Zoho auth URL:', urlError.value);
      }

      // Stop loading once token or URL data is available
      if (zohoAuthToken.value || urlData.value || urlError.value) {
        loading.value = false;
      }
    });

    // Set breadcrumbs when component mounts
    onMounted(() => {
      breadcrumbs.value = [
        { text: 'Home', routerName: 'HomePage' },
        { text: 'Zoho Auth', route: '' },
      ];
    });

    return {
      loading,
      breadcrumbs,
      zohoAuthURL,
      zohoAuthTokenExists,
    };
  },
});
</script>
