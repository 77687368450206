import { GET_ZOHO_AUTH_INFO, SEND_ZOHO_EMAIL_NOW } from '../graphql/zoho_email_queries';
import { GET_USER_PROFILE } from '../graphql/user_queries'; // Adjust the path as necessary
import client from '../urql';


// Fetch Zoho Auth Token
export const fetchAuthToken = async (userId, tokenType = "ZohoMail") => {
  try {
    const { data, error } = await client.query(GET_ZOHO_AUTH_INFO, {
      tokenType,
      userId,
    }).toPromise();

    if (error) {
      console.error('Error fetching auth token:', error);
      throw new Error('Failed to fetch auth token');
    }

    return {
      accessToken: data.getZohoAuthInfo.accessToken,
      accountId: data.getZohoAuthInfo.accountId,
    };
  } catch (error) {
    console.error('Error in fetchAuthToken:', error);
    throw error;
  }
};

// Send Email
export const sendEmail = async (finalEmailContent, fromAddress, toAddress, accessToken, accountId, emailSubject, bccAddress = '', ccAddress = '') => {
  try {
    const { data, error } = await client.query(SEND_ZOHO_EMAIL_NOW, {
      fromAddress,
      toAddress,
      token: accessToken,
      emailSubject,
      emailContent: finalEmailContent,
      accountId,
      bccAddress,
      ccAddress,
    }).toPromise();
    if (error) {
      console.error('Error sending email:', error);
      throw new Error('Failed to send email');
    }
    console.log('Email sent successfully:', data);
    return data;
  } catch (error) {
    console.error('Error in sendEmail:', error);
    throw error;
  }
};


// Fetch User Signature
export const fetchUserSignature = async (userId) => {
  try {
    const { data, error } = await client.query(GET_USER_PROFILE, {
      userId: userId,
    }).toPromise();

    if (error) {
      console.error('Error fetching user profile:', error);
      throw new Error('Failed to fetch user profile');
    }

    const profile = data.getUserProfile.profile;
    return {
      emailFromAddress: profile.emailFromAddress,
      signatureName: profile.signatureName,
      signatureTitle: profile.signatureTitle,
      signatureUrl: profile.signatureUrl,
    };
  } catch (error) {
    console.error('Error in fetchUserSignature:', error);
    throw error;
  }
};