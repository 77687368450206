/*const config = {
    keycloak_config: {
      url: 'http://localhost:8080',
      realm: 'CEODTeam',
      clientId: 'ceod-synergy-24',
      resource: 'ceod-synergy-24',
      onLoad: 'login-required',
      'public-client': true,
      'confidential-port': 0,
      'ssl-required': 'none',
    },
  };
  */
const config = {
    keycloak_config: {
      url: 'https://identity.app.ceodiscovery.com/auth/',
      realm: 'CEOD',
      clientId: 'ceod-synergy',
      resource: 'ceod-synergy',
      onLoad: 'login-required',
      'public-client': true,
      'confidential-port': 0,
      'ssl-required': 'none',
    },
  };
  
  export const { keycloak_config } = config;
  
