<template>
  <div class="w-100">
    <BreadcrumbNav :crumbs="breadcrumbs" />
    <WeeklyDateRangeDisplay :isReload="false" />
    <div class="data-list">
      <!-- Check if there are records -->
      <div v-if="searchResults.length > 0">
        <div v-for="record in searchResults" :key="record.listId" class="row align-items-center mb-1 p-2 bg-light">
          <div class="col text-start">
            <strong>{{ record.name }}</strong> <br />
            <div v-for="(filter, index) in record.searchFilter" :key="index">
              List name {{ index + 1 }}: {{ filter.name }} <br />
            </div>
          </div>
          <!-- Show the fetched records instead of Count -->
          <div class="col text-left">
            <div v-for="result in filterResultsByProfileId(record.profileId)" :key="result.created">
              <a :href="createLink(result.professionalId, result.id, result.status)">
                {{ result.status }}
              </a>
              {{ result.dateRange }} <br /> 
              Updated: {{ formatDateTime(result.updated) }}
            </div>
          </div>
          <div class="col text-end">
            <button class="btn btn-secondary" @click="searchExecs(record.profileId)">Search Execs</button> &nbsp;
            <button class="btn btn-primary" @click="showAllChildren(record.profileId, record.searchFilter)">Filter</button>
          </div>
        </div>
      </div>

      <!-- Show "No records found" if the list is empty -->
      <div v-else>
        <div class="alert alert-warning text-center" role="alert">
          <strong>No records found!</strong> Please try adjusting your search.
        </div>
      </div>
    </div>

    <!-- All Children Modal -->
    <div v-if="showAllChildrenModal" :class="showAllChildrenModal ? 'modal modal-show' : 'modal'">
      <div class="modal-content">
        <span class="close" @click="hideModals">&times;</span>
        <h5>Search Filter Data for List ID: {{ currentListId }}</h5>
        <div v-for="(filter, index) in searchFilterData" :key="index" class="filter-item">
          <h6>Filter {{ index + 1 }} - List ID: {{ filter.list_id }}</h6>
          <pre>{{ filter }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, getCurrentInstance, computed, watchEffect, onMounted } from 'vue';
import { useQuery } from '@urql/vue';
import { WEEKLY_EMAILS_LATEST_BY_PROFESSIONAL_IDS } from '../graphql/weekly_emails_queries';
import { APP_FIRM_SEARCH_RESULTS_BY_FIRM_ID } from '../graphql/firms_queries';
import WeeklyDateRangeDisplay from '../components/WeeklyDateRangeDisplay.vue';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import sessionCheckMixin from '../mixins/CheckWeeklyDateSession';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { formatDateTime } from '@/utils/dateUtils';

export default defineComponent({
  name: 'EmailFirmSearchResults',
  components: {
    WeeklyDateRangeDisplay,
    BreadcrumbNav,
  },
  mixins: [sessionCheckMixin],
  setup() {
    const router = useRouter();
    const store = useStore();
    const userId = computed(() => store.getters['user/getUserId']);
    const firmId = ref('');
    const firm_name = ref('');
    const searchResults = ref([]);
    const totalItems = ref(0);
    const loading = ref(true);
    const searchFilterData = ref([]);
    const showAllChildrenModal = ref(false);
    const currentListId = ref('');
    const professionalIds = ref([]); // This is what we're debugging

    const weeklyEmailResults = ref([]); // Hold the results from the weekly emails query
    
    const breadcrumbs = [
      { text: 'Home', routerName: 'HomePage' },
      { text: 'Weekly Emails', routerName: 'EmailsWeeklyEmails' },
      { text: 'Search', routerName: '' },
    ];

    /*const weeklyStartDate = sessionStorage.getItem('weeklyStartDate');
    const weeklyEndDate = sessionStorage.getItem('weeklyEndDate');*/

    // Create reactive variables for query
    const variables = ref({
      professionalIds: professionalIds.value,
      userId: userId.value,
    });

    // Query for fetching weekly emails by professional IDs
    const { data: weeklyData, executeQuery: fetchWeeklyEmails } = useQuery({
      query: WEEKLY_EMAILS_LATEST_BY_PROFESSIONAL_IDS,
      variables: variables.value,
      pause: true,  // We will trigger this manually
    });

    // Watch for changes in professionalIds, startdate, or enddate and execute the query when ready
    watchEffect(() => {
      if (professionalIds.value.length > 0) {
        variables.value.professionalIds = professionalIds.value;
        fetchWeeklyEmails();
      }
    });

    // Watch effect to handle weekly emails results
    watchEffect(() => {
      if (weeklyData.value) {
        //console.log('Weekly Emails Data:', weeklyData.value); // Debug result from query
        weeklyEmailResults.value = weeklyData.value.weeklyEmailsLatestByProfessionalIds.records;
        //console.log("weeklyEmailResults:",weeklyEmailResults.value);
      }
    });

    // Function to populate professional IDs after firm search and trigger weekly emails query
    const fetchSearchResults = () => {
      const { data: searchData, error: searchError, executeQuery: executeSearch } = useQuery({
        query: APP_FIRM_SEARCH_RESULTS_BY_FIRM_ID,
        variables: { firm_id: firmId.value },
        pause: true,
      });

      watchEffect(() => {
        if (searchData.value) {
          const firmData = searchData.value.appFirmSearchResultsByFirmId;
          searchResults.value = firmData.records;
          firm_name.value = firmData.firmName;
          document.getElementById('header-title').innerText = `Weekly Emails - ${firm_name.value}`;
          totalItems.value = searchResults.value.length;
          loading.value = false;
          // Populate professional IDs and trigger the weekly emails query
          professionalIds.value = searchResults.value.map(record => record.profileId);
          //console.log('Populated professionalIds:', professionalIds.value); // Debug after population
          //console.log("searchResults:",searchResults.value);
        }

        if (searchError.value) {
          console.error('Error fetching data:', searchError.value);
          loading.value = false;
        }
      });

      executeSearch(); // Trigger the firm search query
    };

    // Check if the firm ID is available and trigger fetch
    const checkFirmIdQueryParam = () => {
      if (firmId.value) {
        fetchSearchResults(); // Fetch the search results based on firmId
      } else {
        console.warn('No firm_id query parameter found');
      }
    };

    const showAllChildren = (listId, filterData) => {
      currentListId.value = listId;
      searchFilterData.value = Array.isArray(filterData) ? filterData : [filterData]; // Ensure it's an array
      showAllChildrenModal.value = true;
    };

    const hideModals = () => {
      showAllChildrenModal.value = false;
    };

    

    const filterResultsByProfileId = (profileId) => {
      return weeklyEmailResults.value.filter(result => result.professionalId === profileId);
    };

    const createLink = (profileId, listId, status) => {
      return router.resolve({
        name: 'EmailsWeeklySearchExecs',
        params: {
          profile_id: profileId,  // Assuming you have profileId in result
          list_id: listId,
          status: status
        }
      }).href;
    };

    const { proxy } = getCurrentInstance();
    const searchExecs = (profile_id) => {
      proxy.$router.push({ name: 'EmailsWeeklySearchExecs', params: { profile_id } });
    };

    // When the component is mounted, check the firm ID
    onMounted(() => {
      firmId.value = proxy.$route.params.firmId;
      checkFirmIdQueryParam();
    });

    return {
      firmId,
      firm_name,
      searchResults,
      loading,
      searchFilterData,
      showAllChildrenModal,
      currentListId,
      breadcrumbs,
      showAllChildren,
      hideModals,
      searchExecs,
      weeklyEmailResults,
      createLink,
      filterResultsByProfileId,
      formatDateTime
    };
  }
});
</script>
