// In your mixin file (e.g., mixins/CheckWeeklyDateSession.js)
export function setSessionDates(startDate, endDate) {
  sessionStorage.setItem('weeklyStartDate', startDate);
  sessionStorage.setItem('weeklyEndDate', endDate);
}

export default {
  data() {
    return {
      weeklyStartDate: '',
      weeklyEndDate: '',
    };
  },
  created() {
    this.checkSessionDates();
    this.weeklyStartDate = sessionStorage.getItem('weeklyStartDate');
    this.weeklyEndDate = sessionStorage.getItem('weeklyEndDate');
  },
  methods: {
    checkSessionDates() {
      const weeklyStartDate = sessionStorage.getItem('weeklyStartDate');
      const weeklyEndDate = sessionStorage.getItem('weeklyEndDate');
      if (!weeklyStartDate || !weeklyEndDate) {
        this.$router.push({ name: 'AppWeeklyEmailDateRange' });
      }
    },
  },
};
