import axios from 'axios';
import store from '../store';

export const apiService = {
  login(credentials) {
    return axios.post(`token/`, credentials);
  },
  refresh(refreshToken) {
    return axios.post(`token/refresh/`, { refresh: refreshToken });
  },
  getFirmsData(page, pageSize, sort = [], filter = {}) {
    const token = store.state.accessToken;
    const params = { page: page,page_size: pageSize,sort,filter };
    return axios.get(`data/appfirms/`, {headers: { 'Authorization': `Bearer ${token}` },params });
  },
  getMyFirmsData(username, page, pageSize, sort = [], filter = {}) {
    const token = store.state.accessToken;
    const params = { page: page,page_size: pageSize,sort,filter };
    return axios.get(`data/app-my-firms/${username}/`, {headers: { 'Authorization': `Bearer ${token}` },params });
  },
  getFirmSearchCounts(firm_ids) {
    const token = store.state.accessToken;
    return axios.get('data/firm-search-counts', {headers: { 'Authorization': `Bearer ${token}` },params: { firm_ids }});
  },
  getFirmSearchResults(firm_id) {
    const token = store.state.accessToken;
    return axios.get(`data/firm-search-results/${firm_id}`, {headers: { 'Authorization': `Bearer ${token}` }});
  },
  getFirmSearchResultsByProfileID(firm_id, profile_id) {
    const token = store.state.accessToken;
    return axios.get(`data/firm-search-results-by-profile-id/${firm_id}/${profile_id}`, {headers: { 'Authorization': `Bearer ${token}` }});
  },
  getFirmByID(firm_id) {
    const token = store.state.accessToken;
    return axios.get(`data/firm-search-by-id/${firm_id}`, {headers: { 'Authorization': `Bearer ${token}` }});
  },
  getProfileSearchByID(profile_id) {
    const token = store.state.accessToken;
    return axios.get(`data/app-search-profile/${profile_id}`, {headers: { 'Authorization': `Bearer ${token}` }});
  },
  getAppProfileSearch(params) {
    const token = store.state.accessToken;
    return axios.get('data/app-search-profiles/', {
      headers: { 'Authorization': `Bearer ${token}` },
      params: params
    });
  },
  getAppProfileByName(query) {
    const token = store.state.accessToken;
    return axios.get('data/app-search-by-name/', {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { q: query }
    });
  }
};