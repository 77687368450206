<template>
    <VueFinalModal 
      v-model="localVisible"
      content-class="modal-dialog-centered modal-dialog"
      :classes="['modal-backdrop', 'fade']"
      :show-close-button="true"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
          <button type="button" class="btn btn-danger" @click="confirm">Confirm</button>
        </div>
      </div>
    </VueFinalModal>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  import { VueFinalModal } from 'vue-final-modal';
  
  export default {
    name: 'ConfirmDialog',
    components: { VueFinalModal },
    props: {
      message: String,
      isVisible: Boolean,
    },
    emits: ['confirm', 'cancel', 'update:isVisible'],
    setup(props, { emit }) {
      const localVisible = ref(props.isVisible);
  
      // Sync localVisible with isVisible prop
      watch(() => props.isVisible, (newVal) => {
        localVisible.value = newVal;
      });
  
      // Emit update when localVisible changes (to sync with parent)
      watch(localVisible, (newVal) => {
        emit('update:isVisible', newVal);
      });
  
      const confirm = () => {
        localVisible.value = false;
        emit('confirm'); // Emit confirm event
      };
  
      const cancel = () => {
        localVisible.value = false;
        emit('cancel'); // Emit cancel event
      };
  
      return { localVisible, confirm, cancel };
    },
  };
  </script>
  