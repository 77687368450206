import gql from 'graphql-tag';

// Query for getting the user access token with token_type
export const GET_USER_ACCESS_TOKEN = gql`
  query GETUSERACCESSTOKEN($username: String!, $tokenType: String!) {
    checkZohoToken(username: $username, tokenType: $tokenType) {
      accessToken
      accessTokenExists
      message
    }
  }
`;

// Query for getting the Zoho authorization URL with token_type
export const GET_ZOHO_AUTH_URL = gql`
  query GETZOHOAUTHURL($tokenType: String!) {
    zohoAuthUrl(tokenType: $tokenType) {
      url
    }
  }
`;

