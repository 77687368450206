<template>
  <main>
    <div class="container-fluid">
      <div class="row header-row">
        <!-- First Column -->
        <div class="toggle-icon"><i :class="isCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-left'" @click="toggleCollapse"></i></div>
        <div v-if="!isCollapsed" class="col-3 column-1 main-left-column bg-primary">
          <div class="logo">
            <router-link :to="{ name: 'HomePage' }">
              <img src="../assets/images/CEODiscovery-logo.png" alt="Logo" class="img-fluid" />
            </router-link>
          </div>
          <div class="nav-links">
            <nav class="nav flex-column">
              <!-- Main Links -->
              <router-link class="nav-link" :to="{ name: 'HomePage' }" @click.prevent="reloadPage({ name: 'HomePage' })">Home</router-link>
              <router-link class="nav-link child-link" :to="{ name: 'AppMyFirms' }" @click.prevent="reloadPage({ name: 'AppMyFirms' })">My Firms</router-link>

              <!-- Weekly Emails Section with Toggle -->
              <div><span @click="toggleMenu('weeklyEmails')" class="parent-link">Weekly Emails</span> <i :class="{ rotate: weeklyEmailsLoading }" @click="fetchWeeklyEmailStatus" class="fas fa-sync-alt ms-2" data-bs-toggle="tooltip" title="Refresh the counter"></i></div>
              <div v-if="menus.weeklyEmails" class="submenu">
                <router-link class="nav-link child-link" :to="{ name: 'AppWeeklyEmailDateRange' }" @click.prevent="reloadPage({ name: 'AppWeeklyEmailDateRange' })">New</router-link>
                <router-link
                  v-for="status in WeeklyEmailsStatuses"
                  :key="status.value"
                  class="nav-link child-link"
                  :to="{ name: 'EmailsWeeklySavedList', params: { status: status.value } }"
                  @click.prevent="reloadPage({ name: 'EmailsWeeklySavedList', params: { status: status.value } })"
                >
                  {{ status.label }} ({{ status.count }})
                </router-link>
              </div>

              <!-- Bulk Emails Section with Toggle -->
              
                <div><span @click="toggleMenu('bulkEmails')" class="parent-link">Bulk Emails to Executives</span> <i :class="{ rotate: bulkEmailsLoading }" @click="fetchBulkEmailStatus" class="fas fa-sync-alt ms-2" data-bs-toggle="tooltip" title="Refresh the counter"></i></div>
                <div v-if="menus.bulkEmails" class="submenu">
                  <router-link class="nav-link child-link" :to="{ name: 'AppBulkEmailsExecs' }" @click.prevent="reloadPage({ name: 'AppBulkEmailsExecs' })">New</router-link>
                  <router-link
                    v-for="status in BulkEmailsStatuses"
                    :key="status.value"
                    class="nav-link child-link"
                    :to="{ name: 'AppBulkEmailsCampaignsList', params: { status: status.value } }"
                    @click.prevent="reloadPage({ name: 'AppBulkEmailsCampaignsList', params: { status: status.value } })"
                  >
                    {{ status.label }} ({{ status.count }})
                  </router-link>
                </div>
              
            </nav>
          </div>

          <!-- Bottom Settings and Logout -->
          <div class="bottom-links">
            <p class="text-white mb-0">
              {{ email }} ({{ username }})<br />
              ID: ({{ userid }} - {{ isSuperuser ? 'admin' : 'staff' }})
            </p>
            
            <div class="d-flex align-items-center gap-2">
              <button v-if="username === 'naeemnn'" @click="checkToken">Check Token</button>
              <button @click.prevent="logout">Logout</button>
              <router-link class="nav-link" :to="{ name: 'UserProfile' }"><i class="fas fa-cog"></i> Settings</router-link>
            </div>
          </div>
        </div>
        
        <!-- Second Column -->
        <div :class="isCollapsed ? 'col-12' : 'col-9'" class="column-2 p-4">
          <HeaderApp :title="headerTitle" />
          <router-view />
          <FooterApp :footerText="footerText" />
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import { computed, ref, defineComponent, getCurrentInstance, watchEffect, onMounted } from 'vue';
import { useHead } from '@vueuse/head';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useQuery } from '@urql/vue'; // For GraphQL queries
import { COUNT_WEEKLY_EMAILS_BY_STATUS } from '@/graphql/weekly_emails_queries';
import { COUNT_BULK_EMAIL_CAMPAIGNS_BY_STATUS } from '@/graphql/bulk_emails_to_execs';
import HeaderApp from '@/components/HeaderApp.vue';
import FooterApp from '@/components/FooterApp.vue';
import { initializeTooltips } from '@/utils/tooltipService';

export default defineComponent({
  components: {
    HeaderApp,
    FooterApp,
  },
  methods: {
    logout() {
      this.$keycloak.logout({
        redirectUri: window.location.origin,
      });
    },
    checkToken() {
      console.log('Access Token:' + this.$store.state.accessToken);
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const username = proxy.$username;
    const email = proxy.$email;
    const weeklyEmailsLoading = ref(false);
    const bulkEmailsLoading = ref(false);
    const isCollapsed = ref(false);
    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };
    
    // Fetch userid and superuser status from store
    const userid = computed(() => store.getters['user/getUserId']);
    const isSuperuser = computed(() => store.getters['user/isUserSuperuser']);

    const WeeklyEmailsStatuses = ref([
      { label: 'Draft', value: 'Draft', count: '...' },
      { label: 'Pending', value: 'Pending', count: '...' },
      { label: 'Reconsider', value: 'Reconsider', count: '...' },
      { label: 'Approved', value: 'Approved', count: '...' },
      { label: 'Sent', value: 'Sent', count: '...' },
    ]);

    const BulkEmailsStatuses = ref([
      { label: 'Draft', value: 'Draft', count: '...' },
      { label: 'Pending', value: 'Pending', count: '...' },
      { label: 'Reconsider', value: 'Reconsider', count: '...' },
      { label: 'Approved', value: 'Approved', count: '...' },
      { label: 'Active', value: 'Active', count: '...' },
      { label: 'Pause', value: 'Pause', count: '...' },
      { label: 'Sent', value: 'Sent', count: '...' },
    ]);

    //Start WEEKLY EMAIL Counts
    const { data: weeklyEmailStatusData, error: weeklyEmailStatusError, executeQuery: fetchWeeklyEmailStatusQuery } = useQuery({
      query: COUNT_WEEKLY_EMAILS_BY_STATUS,
      variables: {
        userId: userid.value,  // Assuming userid is defined or replace with your actual reference
        isSuperuser: isSuperuser.value ? 1 : 0, // Assuming isSuperuser is defined or replace with your actual reference
        statuses: WeeklyEmailsStatuses.value.map((s) => s.value),
      },
      pause: true,
    });
    const fetchWeeklyEmailStatus = async () => {
      weeklyEmailsLoading.value = true; // Start rotation
      try {
        await fetchWeeklyEmailStatusQuery({ requestPolicy: 'network-only' });
      } catch (err) {
        console.error('Error fetching weekly email status:', err);
      } finally {
        weeklyEmailsLoading.value = false; // Stop rotation
      }
    };
    watchEffect(() => {
      if (weeklyEmailStatusData.value) {
        const statusCounts = weeklyEmailStatusData.value.countWeeklyEmailsByStatus;
        statusCounts.forEach((statusCount) => {
          const status = WeeklyEmailsStatuses.value.find((s) => s.value === statusCount.status);
          if (status) {
            status.count = statusCount.count;
          }
        });
      }
    });
    watchEffect(() => {
      if (weeklyEmailStatusError.value) {
        console.error('Error fetching weekly email status:', weeklyEmailStatusError.value);
      }
    });
    //End WEEKLY EMAIL Counts

    // Start BULK EMAIL Counts
    const { data: bulkEmailStatusData, error: bulkEmailStatusError, executeQuery: fetchBulkEmailStatusQuery } = useQuery({
      query: COUNT_BULK_EMAIL_CAMPAIGNS_BY_STATUS,
      variables: {
        statuses: BulkEmailsStatuses.value.map((s) => s.value),
      },
      pause: true,
    });
    const fetchBulkEmailStatus = async () => {
      bulkEmailsLoading.value = true; // Start rotation
      try {
        await fetchBulkEmailStatusQuery({ requestPolicy: 'network-only' });
        //console.log("BULK STATUS CLIKED::");
      } catch (err) {
        console.error('Error fetching bulk email status:', err);
      } finally {
        bulkEmailsLoading.value = false; // Stop rotation
      }
    };
    watchEffect(() => {
      if (bulkEmailStatusData.value) {
        const statusCounts = bulkEmailStatusData.value.countBulkEmailCampaignsByStatus;
        statusCounts.forEach((statusCount) => {
          const status = BulkEmailsStatuses.value.find((s) => s.value === statusCount.status);
          if (status) {
            status.count = statusCount.count;
          }
        });
      }
    });
    watchEffect(() => {
      if (bulkEmailStatusError.value) {
        console.error('Error fetching bulk email status:', bulkEmailStatusError.value);
      }
    });
    // End BULK EMAIL Counts


    const reloadPage = (route) => {
      const reloadRoutes = ['HomePage', 'AppMyFirms','EmailsWeeklySavedList'];
      if (reloadRoutes.includes(route.name)) {
        window.location.href = router.resolve(route).href;
      } else {
        router.push(route);
      }
    };

    const headerTitle = computed(() => route.meta.title || 'CEO Discovery');
    const footerText = computed(() => `\u00A9 CEO Discovery ${new Date().getFullYear()}. All Rights Reserved.`);

    useHead({
      title: headerTitle.value,
      meta: [{ name: 'description', content: route.meta.description }],
    });

    // Manage menu toggle state
    const menus = ref({
      weeklyEmails: false,
      bulkEmails: false,
    });

    // Toggle menu visibility
    const toggleMenu = (menu) => {
      if (menu === 'weeklyEmails') {
        menus.value.weeklyEmails = !menus.value.weeklyEmails;
        menus.value.bulkEmails = false;
        if (menus.value.weeklyEmails) {
          fetchWeeklyEmailStatus();
        }
      } else if (menu === 'bulkEmails') {
        menus.value.bulkEmails = !menus.value.bulkEmails;
        menus.value.weeklyEmails = false;
        if (menus.value.bulkEmails) {
          fetchBulkEmailStatus();
        }
      }
    };

    // Check if route is a child of Weekly or Bulk Emails
    onMounted(() => {
      const isWeeklyEmailsRoute = ['AppWeeklyEmailDateRange', 'EmailsWeeklySavedList', 'EmailFirmSearchResults', 'EmailsWeeklySearchExecs'].includes(route.name);
      const isBulkEmailsRoute = ['AppBulkEmailsExecsList', 'AppBulkEmailsExecs', 'AppBulkEmailsCampaignsList', 'AppBulkEmailsCampaign'].includes(route.name);

      // Only update menu state if we're on a relevant route
      if (isWeeklyEmailsRoute) {
        menus.value.weeklyEmails = true;
        fetchWeeklyEmailStatus();
      } else if (!menus.value.weeklyEmails) {
        menus.value.weeklyEmails = false;
      }

      if (isBulkEmailsRoute) {
        menus.value.bulkEmails = true;
        fetchBulkEmailStatus();
      } else if (!menus.value.bulkEmails) {
        menus.value.bulkEmails = false;
      }

      initializeTooltips(); 
    });

    

    return {
      headerTitle,
      footerText,
      username,
      email,
      userid, // Return userid computed from the store
      isSuperuser,
      WeeklyEmailsStatuses,
      reloadPage,
      BulkEmailsStatuses,
      toggleMenu,
      menus,
      fetchWeeklyEmailStatus,
      weeklyEmailsLoading,
      fetchBulkEmailStatus,
      bulkEmailsLoading,
      isCollapsed,
      toggleCollapse,
    };
  },
});
</script>

<style scoped>
.router-link-exact-active{
  font-weight: bold;
  opacity: 50%;
}
.parent-link{
  cursor: pointer;
}
.rotate {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.toggle-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff; /* Icon color */
  background-color: #007bff; /* Background color */
  padding: 8px 10px; /* Padding around the icon */
  border-radius: 50%; /* Rounded corners for a circular look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  z-index: 1000; /* Ensures it stays above other elements */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
  width: 30px;
  height: 30px;

  /* Center the icon */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hover effect for plain CSS */
.toggle-icon:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: scale(1.1); /* Slight zoom effect */
}

.main-left-column {
  transition: all 0.3s ease;
}
</style>