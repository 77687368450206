<template>
    <main>
        <div class="container">
            <div class="content">
                <router-view />
            </div>
        </div>
    </main>
</template>

<script>
import { computed, defineComponent } from 'vue';

import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';


export default defineComponent({
  components: {},
  methods: {},
  setup() {
    const route = useRoute();
    const headerTitle = computed(() => {
      return route.meta.title || 'CEO Discovery';
    });
    useHead({
      title: headerTitle.value,
      meta: [
        {
          name: 'description',
          content: route.meta.description
        }
      ],
      link: [
        {
          rel: 'icon',
          href: require('@/assets/images/CEODiscovery_Symbol.svg').default,
          type: 'image/svg+xml'
        }
      ]
    });
    return {};
  }
});
</script>

<style scoped>
    body, html {
      height: 100%;
      margin: 0;
    }
    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .content h1 {
      font-size: 6rem;
      margin-bottom: 1rem;
    }
    .content p {
      font-size: 1.25rem;
    }
</style>
