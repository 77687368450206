import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// No need to import default Vuetify styles
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'customTheme',
    icons: {
      defaultSet: 'mdi',
      sets: {
        mdi: {
          component: 'mdi', // Use the MDI icons
        },
      },
    },
    themes: {
      customTheme: {
        colors: {
          background: '#FFFFFF',
          surface: '#FFFFFF',
          primary: '#0b0b45',
          secondary: '#086C2D',
          accent: '#0096d3',
          error: '#000000',
          info: '#000000',
          success: '#000000',
          warning: '#000000',
        },
      },
    },
  },
})
export default vuetify
