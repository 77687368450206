import { createStore } from 'vuex';
import { apiService } from '../api/apiService';
import user from './user'; // Import the user module

export default createStore({
  modules: {
    user // Register the user module
  },
  state: {
    accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoyMDM2MTgxMjUzLCJpYXQiOjE3MjA4MjEyNTMsImp0aSI6IjYxZTA2OWQ4MGViYzQxMjY5ODk2MWU5ODNhMzU5NzRkIiwidXNlcl9pZCI6MX0.UrV2iy2e5bxTZNjuOrRNGrxTjZvpbNGQ2AOgv_upUHo',
    refreshToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MjAzNjE4MTI1MywiaWF0IjoxNzIwODIxMjUzLCJqdGkiOiIwYTVmMTA1ZDhmNjE0ZTEwYTY2MTU1M2NkMWYzMWJhNCIsInVzZXJfaWQiOjF9.UpHUE-kZDfgogF5ZOj1EPg1VCe_o2QFZhODaJQNumyM'
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    clearAuthData(state) {
      state.accessToken = '';
      state.refreshToken = '';
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await apiService.login(credentials);
        commit('setAccessToken', response.data.access);
        commit('setRefreshToken', response.data.refresh);
      } catch (error) {
        console.error('Login failed', error);
        throw error;
      }
    },
    async refreshTokenAction({ commit, state }) {
      try {
        const response = await apiService.refresh(state.refreshToken);
        commit('setAccessToken', response.data.access);
      } catch (error) {
        console.error('Token refresh failed', error);
        commit('clearAuthData');
        throw error;
      }
    },
    logout({ commit }) {
      commit('clearAuthData');
    }
  },
  getters: {
    isAuthenticated: state => !!state.accessToken
  }
});
