import gql from 'graphql-tag';

// GraphQL queries and mutations
export const GET_USER_BY_USERNAME = gql`
  query GetUserByUsername($username: String!) {
    getUserByUsername(username: $username) {
        dateJoined
        email
        firstName
        isActive
        id
        isStaff
        isSuperuser
        lastLogin
        lastName
        username
    }
    
  }
`;

export const GET_USER_PROFILE = gql`
  query GetUserProfile($userId: Int!) {
    getUserProfile(userId: $userId) {
      userId
      profile {
        emailFromAddress
        signatureName
        signatureTitle
        signatureUrl
      }
      message
    }
  }
`;

export const CREATE_OR_UPDATE_PROFILE = gql`
  mutation CreateOrUpdateProfile($userId: Int!, $input: UserProfileInput!) {
    createOrUpdateProfile(userId: $userId, input: $input) {
      userId
      profile {
        emailFromAddress
        signatureName
        signatureTitle
        signatureUrl
      }
      message
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      username
      firstName
      lastName
      email
      isStaff
      isActive
      dateJoined
      lastLogin
      isSuperuser
    }
  }
`;