<template>
  <div ref="emailContent">
    <div style="background-color: #f0f0f0; padding: 20px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
      <div
        style="position: relative; padding-bottom: 20px; background-color: white; max-width: 600px; margin: 0 auto; border: 1px solid #ccc; box-sizing: border-box;">
        <div style="padding: 20px 20px 0px 20px">
          <div style="text-align: center;padding-bottom: 5px;"><img src="https://ceod-static.s3.us-east-1.amazonaws.com/ceo-email-header.jpg" alt="CEO Discovery Logo" style="width: 450px; height: auto;" /></div>
          <p style="margin-top:0px;font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">{{ profileName }}:</p>
          <div><p style="font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;" v-for="(headerLine, index) in splitText(emailHeaderText)" :key="'header-' + index">{{ headerLine }}</p></div>
        </div>
        <div v-for="(item, subIndex) in duplicates" :key="item.id">
          <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
            <tbody>
            <tr v-if="subIndex === 0">
              <td colspan="3" style="background-color:#edf1f7; font-family: 'Times New Roman', Times, serif; line-height: 1.2; font-size: 19px; padding:20px 20px 10px 20px;">
                <strong>Executives Matching Multiple Lists</strong>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="background-color:#edf1f7; vertical-align: bottom; padding:0px 20px 0px 20px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                <a :href="item.link" target="_blank" style="color:#325ac6"><strong>{{ item.full_name }}</strong></a>
              </td>
            </tr>
            <tr>
              <td style="background-color:#edf1f7; vertical-align: top; padding:0px 10px 0px 20px; width: 250px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                <strong>{{ item.title }}, {{ item.company_name }}</strong></td>
              <td style="background-color:#edf1f7; white-space: nowrap; text-align: right; padding:0px 10px 0px 10px; width: 150px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                PE Experience: {{ item.sponsored ? 'Yes' : 'No' }}
              </td>
              <td style="background-color:#edf1f7; white-space: nowrap; text-align: left; padding:0px 20px 0px 10px; width: 150px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                P&L: {{ item.pl_size }}
              </td>
            </tr>
            <tr>
              <td colspan="3" style="background-color:#edf1f7; padding:0px 20px 0px 20px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                Sector Experience: {{ item.industry_sectors_compressed }}
              </td>
            </tr>
            <tr>
              <td colspan="3" style="background-color:#edf1f7; padding:0px 20px 20px 20px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                <i>Matched on: {{ item.lists.join(', ') }}</i>
              </td>
            </tr>
          </tbody>
          </table>
        </div>
        <div v-for="(container) in containersCopy" :key="container.id">
          <div v-for="(item, itemIndex) in container.items" :key="item.id">
            <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
              <tbody>
              <tr v-if="itemIndex === 0">
                <td colspan="3" style="vertical-align: bottom;">
                  <div style="margin-top: 0px; padding-top: 20px; padding-left: 20px; font-family: 'Times New Roman', Times, serif; font-size: 19px; line-height: 1.2;">
                    <strong>{{ container.title }}</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="vertical-align: bottom; padding:10px 20px 0px 20px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                  <a :href="item.link" target="_blank" style="color:#325ac6"><strong>{{ item.full_name }}</strong></a>
                </td>
              </tr>
              <tr>
                <td style="vertical-align: top; padding:0px 10px 0px 20px; width: 250px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                  <strong>{{ item.title }}, {{ item.company_name }}</strong>
                </td>
                <td style="white-space: nowrap; text-align:right; padding:0px 10px 0px 10px; width: 150px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                  PE Experience: {{ item.sponsored ? 'Yes' : 'No' }}
                </td>
                <td style="white-space: nowrap; text-align: left; padding:0px 20px 0px 10px; width:150px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                  P&L: {{ item.pl_size }}
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding:0px 20px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
                  Sector Experience: {{ item.industry_sectors_compressed }}
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
        <!-- Signature section -->
        <div>
          <p style="font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;" v-for="(footerLine, index) in splitText(emailFooterText)" 
            :key="'footer-' + index"
            :style="index === 0 ? 'padding:20px 20px 0 20px;' : 'padding:0px 20px 0 20px;'">{{ footerLine }}</p>
        </div>
        <p style="font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2; padding:0 20px 0 20px; margin-bottom: 0;" v-if="userProfile">
          <strong>{{ userProfile.signatureName }}</strong><br/>
          {{ userProfile.signatureTitle }}<br/>
          <a :href="formattedSignatureUrl" target="_blank">CEODiscovery.com</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useQuery } from '@urql/vue';
import { GET_USER_PROFILE } from '../graphql/user_queries';
import { useStore } from 'vuex';

export default {
  name: 'WeeklyEmailPreview',
  props: {
    containersCopy: {
      type: Array,
      required: true,
    },
    duplicates: {
      type: Array,
      required: true,
    },
    profileName: {
      type: String,
      required: true,
    },
    emailHeaderText: {
      type: String,
      required: true,
    },
    emailFooterText: {
      type: String,
      required: true,
    }
  },
  setup() {
    const emailContent = ref(null);
    const userProfile = ref(null);
    const loadingProfile = ref(false);
    const profileError = ref(null);
    const formattedSignatureUrl = ref('');
    const cleanedUrl = ref('');
    const userid = ref(null);
    const isSuperuser = ref(false);

    // Access the Vuex store
    const store = useStore();
    userid.value = store.getters['user/getUserId'];
    isSuperuser.value = store.getters['user/isUserSuperuser'];
    
    const fetchUserProfile = () => {
      loadingProfile.value = true;
      const { data: data_profile, error: queryError, executeQuery: executeProfileQuery } = useQuery({
        query: GET_USER_PROFILE,
        variables: { userId: userid.value },
      });

      watchEffect(() => {
        if (data_profile.value) {
          //console.log('Profile Data:', data_profile.value);
          userProfile.value = data_profile.value.getUserProfile.profile;
          formatSignatureUrl();
        }

        if (queryError.value) {
          console.error('Profile Error:', queryError.value);
          profileError.value = queryError.value;
        }

        loadingProfile.value = false;
      });

      executeProfileQuery();
    };

    const formatSignatureUrl = () => {
      if (userProfile.value && userProfile.value.signatureUrl) {
        const url = userProfile.value.signatureUrl;
        formattedSignatureUrl.value = url.startsWith('http') ? url : `https://${url}`;
        cleanedUrl.value = formattedSignatureUrl.value.replace(/^https?:\/\//, '');
      }
    };

    // Fetch user profile when the component is mounted
    fetchUserProfile();

    const splitText = (text) => {
      return text ? text.split('\n') : [];
    };

    return {
      emailContent,
      userProfile,
      loadingProfile,
      formattedSignatureUrl,
      cleanedUrl,
      splitText,  // Make the method available to the template
    };
  },
};
</script>