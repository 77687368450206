<template>
    <div>
      <div v-if="loading">Processing authorization code...</div>
      <div v-else-if="message">{{ message }}</div>
      <div v-else-if="error">
        {{ error }}
        <!-- Link to go back to the auth page if there's an error -->
        <button @click="goBackToAuth" class="btn btn-primary">Go back to Zoho Auth</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, getCurrentInstance } from 'vue';
  import { useMutation } from '@urql/vue';
  import { useRouter } from 'vue-router';
  
  const SAVE_ZOHO_TOKEN = `
    mutation($username: String!, $authorizationCode: String!, $tokenType: String!) {
      saveZohoToken(username: $username, authorizationCode: $authorizationCode, tokenType: $tokenType)
    }
  `;
  
  export default {
    name: 'ZohoZohoCallBack',
    setup() {
      const loading = ref(true);
      const message = ref('');
      const error = ref('');
      const router = useRouter();
    
      // Get current instance context for username
      const { appContext } = getCurrentInstance();
      const username = appContext.config.globalProperties.$username;
  
      // Mutation to save the Zoho token
      const { executeMutation } = useMutation(SAVE_ZOHO_TOKEN);
  
      // Function to handle the callback
      const searchParams = window.location.search;
      const urlParams = new URLSearchParams(searchParams);
      const authorizationCode = urlParams.get('code');
      const tokenType = urlParams.get('state');  // Extract tokenType (state) from the URL

      const handleCallback = async () => {
        // Step 1: Check if window.location.search is empty
        if (!searchParams) {
          // Redirect to the Zoho auth page if the URL does not contain authorization code
          router.push({ name: 'ZohoZohoAuth' });
          return;
        }
  
        // Step 2: Extract the authorization code and tokenType (state) from the URL
  
        if (!authorizationCode) {
          error.value = 'Authorization code not found';
          loading.value = false;
          return;
        }
  
        if (!tokenType) {
          error.value = 'Token type (state) not found';
          loading.value = false;
          return;
        }
  
        try {
          // Step 3: Call the mutation to save the token using the authorization code and tokenType
          const response = await executeMutation({ username, authorizationCode, tokenType });
          console.log("Response from saveZohoToken:", response);
  
          // Handle success or failure based on mutation response
          if (response.data && response.data.saveZohoToken === 'Zoho token saved successfully') {
            message.value = 'Zoho token saved successfully!';
          } else {
            error.value = response.data?.saveZohoToken || 'Failed to save Zoho token';
          }
        } catch (err) {
          error.value = 'Error processing authorization code: ' + err.message;
        } finally {
          loading.value = false;
        }
      };
  
      // Function to navigate back to Zoho Auth page
      const goBackToAuth = () => {
        router.push({
        name: 'ZohoZohoAuth',
        query: {
            tokenType  // Pass the extracted tokenType as the query parameter
        }
    });
      };
  
      // On mounted, handle the callback
      onMounted(() => {
        handleCallback();
      });
  
      return {
        loading,
        message,
        error,
        goBackToAuth,
      };
    },
  };
  </script>  