<template>
    <div>
      <h3>Select Professionals</h3>
      <p v-if="successMessage" class="text-success">{{ successMessage }}</p> <!-- Success message -->
      
      <!-- Loading Spinner -->
      <div v-if="loadingProfessionals">Loading professionals...</div>
  
      <!-- Professionals List -->
      <div v-else>
        <div v-for="(professional, index) in professionals" :key="index" class="form-check">
          <label class="form-check-label">
            <input 
            type="checkbox" 
            class="form-check-input" 
            :value="professional.id + '|@|' + professional.name + '|@|' + professional.email" 
            v-model="localSelectedProfessionals"
            />
            {{ professional.name }} ({{ professional.email }})
          </label>
        </div>
  
        <!-- Save Button -->
        <button @click="saveSelectedProfessionals" class="btn btn-primary mt-3">Save</button>
      </div>
    </div>
  </template>
    
  <script>
  import { defineComponent, ref, onMounted, watchEffect } from 'vue';
  import { useQuery, useMutation } from '@urql/vue';
  import { GET_PROFESSIONALS_BY_FIRM_ID, SAVE_SELECTED_PROFESSIONALS_BY_FIRM_ID } from '../graphql/firms_queries';
  
  export default defineComponent({
    name: 'GetProfessionalByFirmID',
    props: {
      firmId: {
        type: String,
        required: true
      },
      searchListId: {  // New prop for search_list_id
        type: String,
        required: true
      },
      selectedProfessionals: {  // New prop to pass selected professionals
        type: Array,
        default: () => []
      },
      username: {  // New prop for username
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
      // Reactive references for professionals, loading state, and selected professionals
      const professionals = ref([]);
      const localSelectedProfessionals = ref([...props.selectedProfessionals]);  // Local copy of selectedProfessionals
      const loadingProfessionals = ref(false);
      const successMessage = ref(''); // To show success message
  
      const { executeMutation } = useMutation(SAVE_SELECTED_PROFESSIONALS_BY_FIRM_ID);
  
      // Load professionals for the provided firmId
      const loadProfessionals = () => {
        loadingProfessionals.value = true;
        const queryVariables = { firm_id: props.firmId };
  
        const { data: myFirmProfDataList, error: myFirmProfDataListError } = useQuery({
          query: GET_PROFESSIONALS_BY_FIRM_ID,
          variables: queryVariables,
        });
  
        watchEffect(() => {
          if (myFirmProfDataList.value) {
            professionals.value = myFirmProfDataList.value.getAppProfessionalsByFirmId;  // Updated key
            loadingProfessionals.value = false;
          }
  
          if (myFirmProfDataListError.value) {
            console.error('Error fetching data:', myFirmProfDataListError.value);
            loadingProfessionals.value = false;
          }
        });
      };
  
      const saveSelectedProfessionals = () => {
        console.log("Selected professionals:", localSelectedProfessionals.value);
        // Save the selected professionals directly as they are already formatted
        executeMutation({
            firm_id: props.firmId,
            search_list_id: props.searchListId,
            selectedProfessionals: localSelectedProfessionals.value,  // Already formatted in the checkbox value
            username: props.username
        })
        .then((response) => {
            //console.log('Professionals saved successfully');
            if (response.data && response.data.saveSelectedProfessionals) {
            successMessage.value = 'Professionals saved successfully!';
            emit('saveSelectedProfessionals', localSelectedProfessionals.value);
            setTimeout(() => {
                successMessage.value = '';
            }, 3000);
            }
        })
        .catch((err) => {
            console.error('Error during mutation:', err);
        });
        };



  
      // Call the loadProfessionals method when the component is mounted
      onMounted(() => {
        loadProfessionals();
        localSelectedProfessionals.value = [...props.selectedProfessionals]; // Set pre-selected professionals
        successMessage.value = '';  // Clear success message on modal open
      });
  
      // Return the data and methods for use in the template
      return {
        professionals,
        localSelectedProfessionals,
        loadingProfessionals,
        loadProfessionals,
        saveSelectedProfessionals,
        successMessage
      };
    }
  });
  </script>
  