import { createClient, cacheExchange, fetchExchange } from '@urql/vue';
const client = createClient({
  url: 'https://hal.ceodiscovery.com/api/graphql/',
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    const token = localStorage.getItem('token');
    console.log("Token:")
    console.log(token)
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    };
  },
});
export default client;
//http://localhost:8000
