<template>
  <div class="w-100">
    <div v-if="loading">Loading...</div>
    <div v-if="error">Error: {{ error.message }}</div>
    <BreadcrumbNav v-if="!loading" :crumbs="breadcrumbs" />
    <WeeklyDateRangeDisplay v-if="!loading && (list_save_status === 'New') " :isReload="true" :extraDateContent="extraDateContent" />
    <div v-else><strong>{{ database_dates }}</strong></div>

    <DynamicMessageBar v-if="!loading" label="User Notes" :content="userNotes" alertClass="alert-info" :showEditLink="true" @saveMessageBarContent="saveMessageBarContent('userNotes', $event)" />
    <DynamicMessageBar v-if="!loading" label="Admin Notes" :content="adminNotes" alertClass="alert-warning" :showEditLink="isSuperuser" @saveMessageBarContent="saveMessageBarContent('adminNotes', $event)" />
    <DynamicMessageBar v-if="!loading" label="Email Header Text" :content="emailHeaderText" alertClass="alert-dark" :showEditLink="true" @saveMessageBarContent="saveMessageBarContent('emailHeaderText', $event)" />
    <DynamicMessageBar v-if="!loading" label="Email Footer Text" :content="emailFooterText" alertClass="alert-dark" :showEditLink="true" @saveMessageBarContent="saveMessageBarContent('emailFooterText', $event)" />

    <VueFinalModal v-model="EmailCCListModal" @closed="handleEmailCCListModalClosed" :esc-to-close="true" :click-to-close="true" content-class="custom-modal-content w-75">
      <template #default>
        <button class="close-button" @click="EmailCCListModal = false"><i class="fas fa-times"></i></button>
        <h2>Email List Manager</h2>
        <EmailListManager :initialEmails="EmailCCList" @update-emails="handleEmailsCCListUpdate" />
        <div class="mt-3">
          <h5>Comma-Separated Emails:</h5>
          <p>{{ EmailCCList }}</p>
        </div>
      </template>
    </VueFinalModal>

    <div class="row">
      <div class="col text-start">
        <button class="btn btn-primary" v-if="(list_save_status !== 'Approved' && list_save_status !== 'Sent' && !loading)" @click="hideSearchFormModal">Search Form</button>
        &nbsp; <button class="btn btn-secondary" @click="EmailCCListModal = true">CC Emails ({{ EmailCCList.length }})</button>
      </div>
      <div v-if="list_save_status !== 'Sent' && !loading" class="col text-end">
        <button class="btn btn-secondary" v-if="list_save_status === 'New'" @click="saveUpdateListAsDraft('Draft')">Save</button>
        <button class="btn btn-secondary" v-else @click="saveUpdateListAsDraft(list_save_status)">Save</button>
        &nbsp; <button class="btn btn-secondary" v-if="(list_save_status === 'New' || list_save_status === 'Draft' || list_save_status === 'Reconsider')" @click="saveUpdateListAsDraft('Pending')">Send for Approval</button>
        &nbsp; <button class="btn btn-secondary" v-if="list_save_status === 'Pending'" @click="saveUpdateListAsDraft('Draft')">Move to Draft</button>
        &nbsp; <button class="btn btn-secondary" v-if="list_save_status === 'Pending' && isSuperuser" @click="saveUpdateListAsDraft('Reconsider')">Send for Reconsider</button>
        &nbsp; <button class="btn btn-secondary" v-if="list_save_status === 'Pending' && isSuperuser" @click="saveUpdateListAsDraft('Approved')">Approved</button>
        &nbsp; <button class="btn btn-secondary" v-if="(list_save_status === 'Reconsider' && !isSuperuser) || list_save_status === 'Approved'" @click="handlePreviewEmail">Send Now</button>
        
        &nbsp; <button  v-if="(list_save_status === 'Approved' || list_save_status === 'Reconsider')" class="btn btn-primary test-mode-button" :class="{'btn-danger': testIsOn, 'btn-secondary': !testIsOn}" @click="toggleTestMode">Test Mode {{ testIsOn ? 'On' : 'Off' }}</button>
      </div>
    </div>

    <div class="outer-container">
      <draggable v-model="containers" :group="'outer-group'" class="outer-draggable-container" :animation="200" @start="onStart" @end="onEnd" item-key="id">
        <template #item="{ element }">
          <div :data-id="element.id"  :key="element.id" class="container">
            <div class="container-header">
              <input v-model="element.title" @input="editTitle(element.id, element.title)" class="title-input" />
            </div>
            <draggable v-model="element.items" :group="{ name: 'inner-group', pull: false }"  class="inner-draggable-container" :animation="200" @start="onStart" @end="onEnd" item-key="id">
              <template #item="{ element: itemElement }">
                <div :key="itemElement.id" :class="['draggable-item', itemElement.hasRedOutline ? 'red-outline' : '']" :data-id="itemElement.id">
                  {{ itemElement.name }} ({{ itemElement.title }})
                  <div v-if="(list_save_status !== 'Sent')" class="pt-1"><a :href="itemElement.link" class="icon-link" target="_blank"><i class="fas fa-link"></i></a>
                  <i class="fas fa-edit text-primary ms-2" @click="openEditModal(itemElement)"></i>
                  <i class="fas fa-trash-alt text-danger ms-2" @click="confirmDelete(element.id, itemElement.id, `${itemElement.name} (${itemElement.title})`)"></i></div>
                </div>
              </template>
              <template #footer>
                <button @click="showAllChildren(element.id)">Search Filter</button>
              </template>
            </draggable>
          </div>
        </template>
      </draggable>

      <!-- VueFinalModal for Editing Item -->
      <VueFinalModal v-model="showEditModal" content-class="custom-modal-content w-75">
        <button class="btn-close" @click="showEditModal = false"></button>
        <h3>Edit Item</h3>
        <form @submit.prevent="saveItem">
          <div class="mb-3">
            <label class="form-label">Full Name</label>
            <input type="text" class="form-control" v-model="editItemData.full_name" required />
          </div>
          <div class="mb-3">
            <label class="form-label">Title</label>
            <input type="text" class="form-control" v-model="editItemData.title" required />
          </div>
          <div class="mb-3">
            <label class="form-label">Company Name</label>
            <input type="text" class="form-control" v-model="editItemData.company_name" required />
          </div>
          <div class="mb-3">
            <label class="form-label">P&L Size</label>
            <input type="text" class="form-control" v-model="editItemData.pl_size" required />
          </div>
          <div class="mb-3">
            <label class="form-label">Industry Sectors</label>
            <textarea class="form-control" v-model="editItemData.industry_sectors_compressed" rows="3" required></textarea>
          </div>
          <div class="form-check mb-3">
            <label class="form-check-label"><input class="form-check-input" type="checkbox" v-model="editItemData.sponsored" />
            PE Experience</label>
          </div>
          <button type="submit" class="btn btn-primary">Save</button>
        </form>
      </VueFinalModal>

      <div :class="{ visible: showSearchFormModal, hidden: !showSearchFormModal }" class="draggable-container-fixed search-container" ref="draggableContainer">
        <div class="container-header" ref="containerHeader">
          <span class="fixed-title">Search Container</span>
        </div>
        <input type="text" v-model="searchQuery" placeholder="Search items..." class="search-bar" />
        <draggable v-model="filteredItems" :group="'inner-group'" class="inner-draggable-container" :animation="200" @start="onStart" @end="onEnd" item-key="id">
          <template #item="{ element: itemElement }">
            <div :key="itemElement.id" class="draggable-item">
              {{ itemElement.name }}
              <a :href="itemElement.link" class="icon-link" target="_blank"><i class="fas fa-link"></i></a>
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <button v-if="!loading" class="btn btn-primary" @click="handlePreviewEmail">Preview Email</button>
    <VueFinalModal v-model="showModal" content-class="custom-modal-content">
      <button class="close-button" @click="showModal = false"><i class="fas fa-times"></i></button>
      <WeeklyEmailPreview ref="emailPreview" :containersCopy="containersCopy" :duplicates="duplicates" :profileName="profile_name" :emailHeaderText="emailHeaderText" :emailFooterText="emailFooterText" />
      <button v-if="(list_save_status === 'Approved' || list_save_status === 'Reconsider')" class="btn btn-primary" @click="sendEmailContent">Send Email Now</button>
    </VueFinalModal>

    <div v-if="showAllChildrenModal" :class="showAllChildrenModal ? 'modal modal-show' : 'modal'">
      <div class="modal-content">
        <span class="close" @click="hideModals">&times;</span>
        <h5>Search Filter</h5>
        <pre>{{ childrenData }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref , getCurrentInstance, onMounted, watchEffect } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import draggable from 'vuedraggable';

import sessionCheckMixin from '../mixins/CheckWeeklyDateSession';
import { useQuery, useMutation } from '@urql/vue';
import { VueFinalModal } from 'vue-final-modal';
import { APP_GET_PROFILE_BY_NAME, APP_GET_EXECUTIES_SAVE_SEARCHS_BY_PROFILE_ID } from '../graphql/search_criteria_list';
import { CREATE_OR_UPDATE_WEEKLY_EMAIL_SAVED_LIST, GET_WEEKLY_EMAILS_SAVED_LIST_BY_ID} from '../graphql/weekly_emails_queries'; // Import your mutation
import { useStore } from 'vuex';
import { setSessionDates } from '../mixins/CheckWeeklyDateSession'; // Import the function directly
import { fetchUserSignature, fetchAuthToken, sendEmail } from '../components/ZohoAuthEmailService'; // Adjust the path as necessary

import WeeklyDateRangeDisplay from '../components/WeeklyDateRangeDisplay.vue';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import WeeklyEmailPreview from '@/components/WeeklyEmailPreview.vue';
import DynamicMessageBar from '@/components/DynamicMessageBar.vue';
import ZohoMailAuthChecker from '@/components/ZohoMailAuthChecker';
import EmailListManager from '@/components/EmailListManager.vue';
import CompressedIndustryService from '@/components/CompressedIndustryService.js'; 

export default defineComponent({
  name: 'EmailFirmSearchExecutives',
  mixins: [sessionCheckMixin],
  components: {
    VueFinalModal,
    draggable,
    WeeklyDateRangeDisplay,
    BreadcrumbNav,
    WeeklyEmailPreview,
    DynamicMessageBar,
    EmailListManager
  },
  setup() {
    const showModal = ref(false);
    const emailContent = ref(null);
    const route = useRoute();
    const router = useRouter();
    const showAllChildrenModal = ref(false);
    const showSearchFormModal = ref(false);
    const childrenData = ref({});
    const profile_id = ref('');
    const list_id = ref('');
    const list_save_status = ref('New');
    const profile_name = ref('');
    const profile_email = ref('');
    const firm_id = ref('');
    const firm_name = ref('');
    const containers = ref([]);
    const containersCopy = ref([]);
    const duplicates = ref([]);
    const searchItems = ref([]);
    const searchQuery = ref('');
    const filteredItems = ref([]);
    const totalItems = ref([]);
    const breadcrumbs = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const database_dates = ref(null);
    //getListWeeklyEmailsFromSavedLists
    const store = useStore(); // Access the Vuex store
    const extraDateContent = computed(() => profile_name.value + ' | ' + profile_email.value);

    const userNotes = ref('');
    const adminNotes = ref('');
    const emailHeaderText = ref('');
    const emailFooterText = ref('You may reach out directly or we can make a warm introduction.');
    const multipleList = ref(false);
    const multipleExecs = ref(false);
    const UniqueExecsTotal = ref(0);
    const EmailCCListModal = ref(false);
    const EmailCCList = ref([]);
    const initialEmailCCList = ref([]);
    const handleEmailsCCListUpdate = (updatedEmails) => {
      //console.log("UPDATING>>>>",updatedEmails);
      EmailCCList.value = updatedEmails ? updatedEmails.split(',').map(email => email.trim()) : [];
    };
    const handleEmailCCListModalClosed = () => {
      const hasChanges = JSON.stringify(initialEmailCCList.value) !== JSON.stringify(EmailCCList.value);
      if (hasChanges) {
        //console.log("AUTO SAVE!!");
        saveUpdateListAsDraft(list_save_status.value, false);
      }
    };
    const updateInitialEmailCCList = () => {
      initialEmailCCList.value = [...EmailCCList.value];
    };

    const setUniqueExecutivesTotal = () => {
        const uniqueExecutivesSet = new Set();
        
        containers.value.forEach(container => {
            container.items.forEach(item => {
                uniqueExecutivesSet.add(item.id);
            });
        });
        
        UniqueExecsTotal.value = uniqueExecutivesSet.size;
        multipleExecs.value = UniqueExecsTotal.value > 1;

        const nonEmptyContainers = containers.value.filter(container => container.items.length > 0);
        multipleList.value = nonEmptyContainers.length > 1;
        
        if (multipleList.value) {
            let firstIterationItems = new Set(nonEmptyContainers[0].items.map(item => item.id));
            multipleList.value = false;

            for (let i = 1; i < nonEmptyContainers.length; i++) {
                const currentItemsSet = new Set(nonEmptyContainers[i].items.map(item => item.id));
                let isUnique = false;

                for (const id of currentItemsSet) {
                    if (!firstIterationItems.has(id)) {
                        isUnique = true;
                        break;
                    }
                }

                if (isUnique) {
                    multipleList.value = true;
                }
            }

            const uniqueContainersSets = new Set([JSON.stringify([...firstIterationItems])]);
            let containersHaveSameContents = false;

            nonEmptyContainers.forEach((container, index) => {
                if (index > 0) {
                    const containerItemsSet = JSON.stringify([...new Set(container.items.map(item => item.id))]);
                    if (uniqueContainersSets.has(containerItemsSet)) {
                        containersHaveSameContents = true;
                    }else{
                      containersHaveSameContents = false;
                    }
                    uniqueContainersSets.add(containerItemsSet);
                }
            });

            if (containersHaveSameContents) {
                multipleList.value = true;
            }
        } else {
            multipleList.value = false;
        }
    };
    watchEffect(() => {
        setUniqueExecutivesTotal();
    });

    watchEffect(() => {
      if (list_save_status.value === 'New') {
        //console.log("UniqueExecsTotal.value:",UniqueExecsTotal.value);
        emailHeaderText.value = `The following ${multipleExecs.value ? 'executives have' : 'executive has'} recently been added to the CEO Discovery platform who ${multipleExecs.value ? 'match' : 'matches'} your criteria for the saved list${multipleList.value ? 's' : ''} below:`;
      }
    });
   

    const saveMessageBarContent = (type, newContent) => {
      //console.log('Received saveContent event for:', type, 'with content:', newContent); // Log to check if event is received
      if (type === 'userNotes') userNotes.value = newContent;
      if (type === 'adminNotes') adminNotes.value = newContent;
      if (type === 'emailHeaderText') emailHeaderText.value = newContent;
      if (type === 'emailFooterText') emailFooterText.value = newContent;
    };

    
    const testIsOn = ref(false);
    const toggleTestMode = () => {
      testIsOn.value = !testIsOn.value;
    };
    // App context and proxy
    const { appContext } = getCurrentInstance();
    const appProfileURL = appContext.config.globalProperties.$AppProfileURL;
    const username = appContext.config.globalProperties.$username;
    const variables = ref({ search_name_query: searchQuery.value });

    const userid = computed(() => store.getters['user/getUserId']);
    const isSuperuser = computed(() => store.getters['user/isUserSuperuser']);

    // Use ZohoMailAuthChecker to get the token
    const isConnected = ref(false);
    const { zohoAuthToken, tokenError } = ZohoMailAuthChecker(username, 'ZohoMail');
    watchEffect(() => {
      if (zohoAuthToken.value) {
        isConnected.value = true;
      }
      if (tokenError.value) {
        console.error("Authentication Error:", tokenError.value);
        isConnected.value = false;
      }
    });

    // Duplicate checking function
    const handlePreviewEmail = () => {
      containersCopy.value = JSON.parse(JSON.stringify(containers.value));// Deep copy
      console.log("containersCopy:::",containersCopy);
      duplicates.value = [];
      let allItems = [];
      // Gather all items from all containers
      containersCopy.value.forEach((container) => {
        allItems = allItems.concat(container.items);
      });
      //console.log("allItems:",allItems);
      let seenItemsMap = new Map();
      // Loop through all items and check for duplicates
      allItems.forEach((item) => {
        if (seenItemsMap.has(item.id)) {
          const originalItem = seenItemsMap.get(item.id);
          const newCompressedSectors = item.industry_sectors_compressed.split(',').map(s => s.trim());
          const originalCompressedSectors = originalItem.industry_sectors_compressed.split(',').map(s => s.trim());
          const mergedSectors = [...new Set([...originalCompressedSectors, ...newCompressedSectors])];  // Remove duplicates
          originalItem.industrySectorsCompressed = mergedSectors;
          originalItem.industry_sectors_compressed = mergedSectors.join(', ');
          seenItemsMap.set(item.id, originalItem);
          duplicates.value = duplicates.value.filter(dup => dup.id !== item.id);
          duplicates.value.push(originalItem);
        } else {
          seenItemsMap.set(item.id, item);
        }
      });
      // Now, for each duplicate, find the list names (from complete_record.name) where it appears
      duplicates.value.forEach(duplicate => {
          duplicate.lists = []; 
          containersCopy.value.forEach(container => {
              const isInContainer = container.items.some(item => item.id === duplicate.id);
              if (isInContainer) {
                  //console.log("container----:", container);
                  duplicate.lists.push(container.complete_record.complete_record.name);
              }
          });
      });
      //console.log("Duplicates with list names:", duplicates.value);
      // Remove duplicates from the containers
      containersCopy.value.forEach((container) => {
        container.items = container.items.filter(item => !duplicates.value.some(dup => dup.id === item.id));
      });
      // Remove containers with no items left after filtering
      containersCopy.value = containersCopy.value.filter(container => container.items.length > 0);
      showModal.value = true;
    };


    const showAllChildren = (containerId) => {
      const record = containers.value.find(item => item.id === containerId);
      if (record) {
        childrenData.value = record.complete_record.complete_record;
        showAllChildrenModal.value = true;
      }
    };
    const hideModals = () => {
      showAllChildrenModal.value = false;
    };
    const hideSearchFormModal = () => {
      showSearchFormModal.value = !showSearchFormModal.value;
    };

    // Send Email
    const emailPreview = ref(null);
    const sendEmailContent = async () => {
      // Confirmation dialog
      const confirmation = window.confirm('Are you sure you want to send this email?');
      if (!confirmation) {
        console.log('Email sending canceled by user.');
        return; // Exit the function if the user cancels
      }
      if (emailPreview.value?.emailContent) {
        const content = emailPreview.value.emailContent.innerHTML;
        try {
          const userId = userid.value;
          const userSignature = await fetchUserSignature(userId);
          const fromAddressEmail = userSignature.emailFromAddress;
          const signatureName = userSignature.signatureName;
          
          const fromAddress = `${signatureName} <${fromAddressEmail}>`;

          let toAddress = `${profile_name.value} <${profile_email.value}>`;
          let bccAddress = '19qj39v_mfa1voz@mails5.zohocrm.com';
          let ccAddress = EmailCCList.value.join(', ');

          if(testIsOn.value){
            toAddress = fromAddress;
            bccAddress = '';
            ccAddress = 'naeemnn@gmail.com, naeemnnpk@yahoo.com';
          }

          const emailSubject = 'CEO Discovery: Recently Added Executives';
          const detailedConfirmation = window.confirm(`
            Please confirm the email details:\n
            From: ${fromAddress}\n
            To: ${toAddress}\n\
            CC: ${ccAddress}\n
            BCC: ${bccAddress}\n
            Subject: ${emailSubject}\n\n
            Are you sure you want to send this email?
          `);
          if (!detailedConfirmation) {
            return;
          }
          const { accessToken, accountId } = await fetchAuthToken(userId);
          const result = await sendEmail(
            content,
            fromAddress,
            toAddress,
            accessToken,
            accountId,
            emailSubject,
            bccAddress,
            ccAddress
          );
          console.log('Email sent successfully:', result);
          if(!testIsOn.value){
            await saveUpdateListAsDraft('Sent');
          }
          //console.log('Draft saved with status "Sent".');
        } catch (error) {
          console.error('Failed to send email:', error);
        }
      }
    };
  
    //Fetch search items based on query search form
    const { data: data_profile, error: profileError, executeQuery: executeProfileQuery } = useQuery({
      query: APP_GET_PROFILE_BY_NAME,
      variables: variables.value,
      pause: true, // Prevent automatic execution
    });
    watchEffect(() => {
      if (searchQuery.value) {
        variables.value.search_name_query = searchQuery.value.trim().toLowerCase();
        executeProfileQuery();
      }
    });
    watchEffect(() => {
      if (data_profile.value) {
        if (Array.isArray(data_profile.value.appGetProfileByName)) {
          searchItems.value = data_profile.value.appGetProfileByName.map(transformItem);
          // Filter items directly
          const query = searchQuery.value.trim().toLowerCase();
          filteredItems.value = query
            ? searchItems.value.filter(item => item.full_name.toLowerCase().includes(query))
            : searchItems.value;
        } else {
          searchItems.value = []; // Clear the search items if the response format is unexpected
        }
      }
      if (profileError.value) {
        searchItems.value = []; // Clear the search items if there is an error
      }
    });
    //end search form

     watchEffect(() => {
      if (list_save_status.value) {
        if (list_save_status.value==="Approved") {
          showSearchFormModal.value = false;
        }
      }
    });
    
    const showEditModal = ref(false);
    const editItemData = ref({
      title: '',
      full_name: '',
      company_name: '',
      pl_size: '',
      pe_expertise: false,
      industry_sectors_compressed: '',
      pl_responsibility: false,
      sponsored: false
    });
    const currentEditingItem = ref(null); // Keep track of the current item being edited
    
    const openEditModal = (item) => {
      currentEditingItem.value = item;
      editItemData.value = { ...item }; // Load item data into modal form
      showEditModal.value = true;
    };

    const saveItem = () => {
      // Concatenate full_name, title, and company_name to create the 'name' field
      editItemData.value.name = `${editItemData.value.full_name}, ${editItemData.value.title} @ ${editItemData.value.company_name}`;
      // Update the item in the list with the edited data
      Object.assign(currentEditingItem.value, editItemData.value); // Apply changes to the original item
      showEditModal.value = false; // Close modal after saving
    };


    const confirmDelete = (containerId, itemId, itemDescription) => {
      if (confirm(`Are you sure you want to delete the item: "${itemDescription}"?`)) {
        deleteItem(containerId, itemId);
      }
    };
    const deleteItem = (containerId, itemId) => {
      containers.value.forEach((container) => {
        if (container.id === containerId) {
          container.items = container.items.filter(item => item.id !== itemId);
        }
      });
    };
    const transformItem = (item) => {
      const currentTitle = item.experienceAggregate ? item.experienceAggregate.currentTitle : '';
      const exclusiveTitle = item.experienceAggregate ? item.experienceAggregate.exclusiveTitle || '' : '';
      let title = '';
      if (currentTitle) {
          title = (currentTitle === 'Other' || currentTitle === 'other') ? exclusiveTitle : currentTitle;
      }
      const name = `${item.name.fullName}, ${title} @ ${item.experienceAggregate && item.experienceAggregate.companyName ? item.experienceAggregate.companyName : ''}`;
      return {
        ...item,
        id: item.id,
        title,
        full_name: item.name.fullName,
        company_name: item.experienceAggregate ? item.experienceAggregate.companyName || '' : '',
        pl_size: item.experienceAggregate ? item.experienceAggregate.plSizeCompressed || '' : '',
        pe_expertise: item.experienceAggregate ? item.experienceAggregate.plResponsibility : false,
        industry_sectors_compressed: item.industrySectorsCompressed ? item.industrySectorsCompressed.join(', ') : '',
        industry_sectors: item.industrySectors ? item.industrySectors.join(', ') : '',
        pl_responsibility: item.experienceAggregate ? item.experienceAggregate.plResponsibility : false,
        sponsored: item.expertise ? item.expertise.sponsored : false,
        name,
        link: `${appProfileURL}${item.id}`
      };
    };

    // Edit title of a container
    const editTitle = (id, newTitle) => {
      const container = containers.value.find(c => c.id === id);
      if (container) {
        container.title = newTitle;
      }
    };
    const weeklyStartDate = sessionStorage.getItem('weeklyStartDate');
    const weeklyEndDate = sessionStorage.getItem('weeklyEndDate');
    // Watch firm_name and firm_id and update breadcrumbs when they change
    watchEffect(() => {
      if (firm_name.value && firm_id.value && list_save_status.value) {
        breadcrumbs.value = [
          { text: 'Home', routerName: 'HomePage' },
          { text: `Weekly Emails - Search Results - ${firm_name.value}`, routerName: 'EmailFirmSearchResults', params: { firmId: firm_id.value } },
          { text: `Search Executives for ${profile_name.value} - ${list_save_status.value}`, routerName: '' },
        ];
      }
    });

    // Mutation for saving as a draft
    const { executeMutation: executeSaveMutation } = useMutation(CREATE_OR_UPDATE_WEEKLY_EMAIL_SAVED_LIST);
    // Function to save or update list with a dynamic status
    const saveUpdateListAsDraft = async (status = 'Draft', redirectAfterSave = true) => {
      const variables = {
        id: list_id.value || null, // Include id for updating
        listname: `${profile_name.value} (${profile_email.value}) - ${firm_name.value}`,
        firmId: firm_id.value,
        professionalId: profile_id.value,
        dateRange: `${weeklyStartDate} - ${weeklyEndDate}`,
        content: JSON.stringify(containers.value),
        duplicateContent: JSON.stringify(duplicates.value),
        savedBy: userid.value,
        emailStatus: status === 'Draft' ? 0 : 1,
        status: status,
        notesFromAdmin: adminNotes.value || null,
        notesFromUser: userNotes.value || null,
        emailHeaderText: emailHeaderText.value || null,
        emailFooterText: emailFooterText.value || null,
        ccAddress: EmailCCList.value.join(', ') || null
      };
      try {
        const result = await executeSaveMutation(variables);
        if (result.data.createOrUpdateWeeklyEmailSavedList.message) {
          const newListId = result.data.createOrUpdateWeeklyEmailSavedList.record.id;
          list_save_status.value = status;
          list_id.value = newListId;
          if (redirectAfterSave) {
            alert(`List updated with status: ${status}`);
            window.location.href = router.resolve({name: 'EmailFirmSearchResults',params: {firmId: firm_id.value}}).href;
          }
        } else {
          alert('Failed to update list.');
        }
      } catch (error) {
        console.error(`Error updating list with status: ${status}`, error);
      }
    };

    let ccListUpdated = false;
    const getWeeklyEmailsSavedListByID = () => {
      const queryVariablesByID = ref({
        id: parseInt(list_id.value, 10),
        userId: userid.value,
        isSuperuser: isSuperuser.value ? 1 : 0,
      });
      const { data: saveListData, error: queryError, executeQuery: executeGetListByIDQuery } = useQuery({
        query: GET_WEEKLY_EMAILS_SAVED_LIST_BY_ID,
        variables: queryVariablesByID.value,
        pause: true,
      });
      watchEffect(() => {
        if (saveListData.value) {
          loading.value = false;
          const record = saveListData.value.getListWeeklyEmailsFromSavedLists;
          if (record) {
            containers.value = record.content ? JSON.parse(JSON.parse(record.content)) : [];
            //console.log("Complete Container..:",containers.value);
            profile_name.value = record.professionalName.split(' ')[0];
            profile_email.value = record.professionalEmail;
            firm_id.value = record.firmId;
            firm_name.value = record.firmName; // Assume this is available in Vuex
            database_dates.value = record.dateRange;
            userNotes.value = record.notesFromUser;
            adminNotes.value = record.notesFromAdmin;
            emailHeaderText.value = record.emailHeaderText;
            emailFooterText.value = record.emailFooterText;
            
            if (!ccListUpdated) {
              //console.log("CAlling AGAIN...");
              handleEmailsCCListUpdate(record.ccAddress || '');
              updateInitialEmailCCList();
              ccListUpdated = true;  // Set flag to true after calling
            }
           
            const [startDate, endDate] = database_dates.value.split(' - ');
            if (startDate && endDate) {
              setSessionDates(startDate, endDate);
            }

            if(list_save_status.value !== record.status && profile_id.value){
              router.push({
                name: 'EmailsWeeklySearchExecs',
                params: {
                  profile_id: profile_id.value,
                  list_id: list_id.value,
                  status: record.status
                }
              });
            }

          } else {
            // Redirect if no record is found
            router.push({ name: 'AppWeeklyEmailDateRange' });
          }
        }

        if (queryError.value) {
          error.value = queryError.value;
          // Optionally, you can also redirect on error
          router.push({ name: 'AppWeeklyEmailDateRange' });
        }
      });
      watchEffect(() => {
        // Ensure that the userId is present before executing the query
        //console.log("userid.value:::::",userid.value);
        //console.log("isSuperuser.value:::::",isSuperuser.value);
        if (userid.value && isSuperuser.value !== undefined) {
          queryVariablesByID.value.userId = userid.value;
          queryVariablesByID.value.isSuperuser = isSuperuser.value ? 1 : 0;
          executeGetListByIDQuery();
        }
      });
    };

    watchEffect(() => {
      if (firm_name.value) {
        document.getElementById('header-title').innerText = `Weekly Emails - ${firm_name.value}`;
      }
    });

    // start search result function
    const fetchSearchResults_apis = () => {
      const { data, error, executeQuery } = useQuery({
        query: APP_GET_EXECUTIES_SAVE_SEARCHS_BY_PROFILE_ID,
        variables: { profile_id: profile_id.value, weeklyStartDate, weeklyEndDate }
      });
      watchEffect(() => {
        if (data.value) {
          loading.value = false;
          const searchResults = data.value.appGetExecutiesSaveSearchsByProfileId.searchRecords;
          firm_name.value = data.value.appGetExecutiesSaveSearchsByProfileId.firmName;
          firm_id.value = data.value.appGetExecutiesSaveSearchsByProfileId.profileData.firmId;
          profile_name.value = data.value.appGetExecutiesSaveSearchsByProfileId.profileData.name.fullName.split(' ')[0];
          profile_email.value = data.value.appGetExecutiesSaveSearchsByProfileId.profileData.email;
          totalItems.value = searchResults.length;
          document.getElementById('header-title').innerText = `Weekly Emails - ${firm_name.value}`;
          containers.value = searchResults.map(record => {            
            return {
              id: record.id,
              title: record.name,
              pl_size: record.searchedParameters.executive.experience.plSizeCompressed,
              industry_sectors: record.searchedParameters.executive.industrySectors,
              industry_sectors_compressed: record.searchedParameters.executive.industrySectorsCompressed,
              items: [],
              total_items: 0,
              complete_record: record
            };
          });
          
          const executivesData = data.value.appGetExecutiesSaveSearchsByProfileId.executives;
          let containersCount = 0;
          const uniqueExecutivesSet = new Set(); // Set to store unique executive IDs

          executivesData.forEach(executiveData => {
              const container = containers.value.find(c => c.id === executiveData.searchId);
              if (container) {
                  //Filter and transform items, ensuring only unique IDs are added
                  executiveData.executivesData
                      .map(transformItem)
                      .filter(item => {
                          if (!uniqueExecutivesSet.has(item.id)) {
                              uniqueExecutivesSet.add(item.id); // Add to Set if not present
                              return true;
                          }
                          return false;
                      });
                  // container.items = uniqueItems; // Assign unique items to container
                  // container.total_items = uniqueItems.length;
                  // if (uniqueItems.length > 0) {
                  //     containersCount++;
                  // }
                  const allItems = executiveData.executivesData.map(transformItem);
                  container.items = allItems; // Assign all items to container
                  container.total_items = allItems.length;
                  if (allItems.length > 0) {
                      containersCount++;
                  }
              }
          });
          if (uniqueExecutivesSet.size > 0) {
                UniqueExecsTotal.value = uniqueExecutivesSet.size;
            }

          // Set multipleList and multipleExecs based on counts
          multipleList.value = containersCount > 1;
          multipleExecs.value = uniqueExecutivesSet.size > 1;
          //console.log("containersCount::::",containersCount);
          //console.log("uniqueExecutivesSet::::",uniqueExecutivesSet.size);
        }
        //remove the proxy
        const rawContainers = containers.value.map(container => ({
          id: container.id,
          title: container.title,
          //pl_size: [...container.pl_size], // Unwrap the Proxy
          pl_size: container.pl_size, // Unwrap the Proxy
          industry_sectors: [...container.industry_sectors], // Unwrap the Proxy
          industry_sectors_compressed: [...container.industry_sectors_compressed], // Unwrap the Proxy
          complete_record: container,
          items: container.items.map(item => ({
            ...item,
            name: item.name,
            id: item.id
          }))
        }));
        // Now assign this flattened data to your containers
        containers.value = rawContainers;
        //console.log("Complete Container..:",containers.value);
        if (error.value) {
          console.error('Error fetching data:', error.value);
        }
      });
      executeQuery();
    };
    // end search result function

    

    // Mounted lifecycle hook
    onMounted(() => {
      profile_id.value = route.params.profile_id;
      list_id.value = route.params.list_id;
      if (route.params.status && route.params.status.trim() !== '') {
        list_save_status.value = route.params.status;
      }

      if (!list_id.value) {
        fetchSearchResults_apis(); // Fetch the initial data when the component mounts
      }else{
        getWeeklyEmailsSavedListByID();
      }//endif

      // Draggable container setup
      const draggableContainer = document.querySelector('.draggable-container-fixed');
      const containerHeader = document.querySelector('.container-header');
      let isDragging = false;
      let offsetX, offsetY;
      const onMouseMove = (e) => {
        if (isDragging) {
          draggableContainer.style.left = `${e.clientX - offsetX}px`;
          draggableContainer.style.top = `${e.clientY - offsetY}px`;
        }
      };
      const onMouseUp = () => {
        isDragging = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
      const onMouseDown = (e) => {
        isDragging = true;
        offsetX = e.clientX - draggableContainer.offsetLeft;
        offsetY = e.clientY - draggableContainer.offsetTop;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      };
      containerHeader.addEventListener('mousedown', onMouseDown);
    });
    
    const { compressProfileIndustrySector } = CompressedIndustryService;
    const onStart = () => {//evt
      console.log('Drag ended in container with ID From:');
    };

    const onEnd = () => {//evt
      // Loop through all containers
      containers.value.forEach((container) => {
        container.items.forEach((item, index) => {
          if (!item.industry_sectors_compressed || item.industry_sectors_compressed === '') {
            const duplicateItem = container.items.find((otherItem, otherIndex) => {
              return otherIndex !== index && otherItem.id === item.id;
            });
            if (duplicateItem) {
              container.items.splice(index, 1);
              return;
            }
            const itemIndustrySectorsArray = typeof item.industry_sectors === 'string' ? item.industry_sectors.split(',').map(s => s.trim()): item.industry_sectors;  // If already an array, use it directly
            const compressedArray = compressProfileIndustrySector(itemIndustrySectorsArray, container.industry_sectors_compressed);
            if (compressedArray.length > 0) {
              item.industry_sectors_compressed = compressedArray.join(', ');
            } else {
              // Add a special class to make the item prominent (e.g., red outline)
              item.hasRedOutline = true; // Add a flag to dynamically apply a class in the template
            }
          }
        });
      });
    };
    

    return {
      showModal,
      saveUpdateListAsDraft,
      emailContent,
      profile_name,
      profile_email,
      sendEmailContent,
      containers,
      containersCopy,
      duplicates,
      searchQuery,
      filteredItems,
      editTitle,
      onStart,
      onEnd,
      profile_id,
      firm_id,
      firm_name,
      breadcrumbs,
      loading,
      error,
      childrenData,
      showAllChildrenModal,
      showAllChildren,
      hideModals,
      showSearchFormModal,
      hideSearchFormModal,
      handlePreviewEmail,
      userid,
      list_id,
      list_save_status,
      isSuperuser,
      database_dates,
      emailPreview,
      showEditModal,
      editItemData,
      openEditModal,
      saveItem,
      confirmDelete,
      deleteItem,
      userNotes,
      adminNotes,
      emailHeaderText,
      emailFooterText,
      saveMessageBarContent,
      extraDateContent,
      testIsOn,
      toggleTestMode,
      username,
      EmailCCListModal,
      EmailCCList,
      handleEmailsCCListUpdate,
      handleEmailCCListModalClosed
    };

  },//end setup()
  created() {
    // Check session dates on component creation
    this.checkSessionDates();
  }
});
</script>
<style scoped>
  .hidden, .visible {transition: opacity 0.5s linear;} .hidden {visibility: hidden; opacity: 0; transition-delay: 0s;} .visible {visibility: visible; opacity: 1;}
  .test-mode-button.btn-secondary { background-color: gray !important; color: white; } 
  .test-mode-button.btn-danger { background-color: red  !important; color: white; }
  .red-outline {border: 3px solid red !important;}
</style>