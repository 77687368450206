<template>
    <div class="container">
      <BreadcrumbNav :crumbs="breadcrumbs" />
      <DynamicMessageBar
        v-if="!loading"
        label="User Notes"
        :content="userNotes"
        alertClass="alert-info"
        :readonly="true"
      />
      <DynamicMessageBar
        v-if="!loading"
        label="Admin Notes"
        :content="adminNotes"
        alertClass="alert-warning"
        :readonly="true"
      />
      <div v-if="campaignData">
        <!-- Campaign Details Card -->
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h5 class="card-title">Campaign ({{ statusCampaignLabels[campaignData.active] }})</h5>
          </div>
          <div class="card-body">
            <p>Created: {{ formatDateTime(campaignData.created) }}</p>
            <p>Ended: {{ formatDateTime(campaignData.ended) || 'N/A' }}</p>
            <p>Search List ID: {{ campaignData.searchListId }}</p>
            
            <h5 class="card-title pt-4">Email Template</h5>
            <div class="pt-2 pb-2" v-html="email_template"></div>
            <p v-if="['Pause','Active'].includes(statusCampaignLabels[campaignData.active])">
              <strong class="text-danger">Note:</strong> Changes made to the email template will apply to the remaining executives in the queue.
            </p>
          </div>
        </div>
  
        <!-- Executives Status Card -->
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h5 class="card-title">Executives ({{ totalExecutives }})</h5>
          </div>
          <div class="card-body">
            <div class="row text-center">
              <div class="col"><strong>No Action:</strong> {{ statusCounts.noAction }}</div>
              <div class="col"><strong>Sent:</strong> {{ statusCounts.sent }}</div>
              <div class="col"><strong>Error:</strong> {{ statusCounts.error }}</div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
            <input 
            type="text" 
            v-model="searchTerm" 
            placeholder="Search..." 
            class="form-control me-2" 
            style="max-width: 300px;"
            />
        </div>
        <VueTableLite
        :rows="table.rows"
        :columns="table.columns"
        :is-loading="table.isLoading"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        :page-size="table.pageSize"
        @do-search="doSearch"
        @is-finished="tableLoadingFinish"
        wrapper-class="w-100"
        />
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div>
            <button class="btn btn-secondary" @click="goBackPage">Back</button>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Loading campaign data...</p>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watchEffect, reactive, onMounted, computed, getCurrentInstance} from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useQuery } from '@urql/vue';
  import { GET_BULK_EMAIL_EXC_GET_ALL_EXECS_SEARCH_LIST, GET_BULK_EMAIL_CAMPAIGN_BY_ID } from '../graphql/bulk_emails_to_execs';
  import { formatDateTime } from '@/components/DateFormatter';
  import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
  import DynamicMessageBar from '@/components/DynamicMessageBar.vue';
  import { useStore } from 'vuex';
  import VueTableLite from 'vue3-table-lite';
  
  export default {
    name: 'AppBulkEmailsSentViewStat',
    components: {
      BreadcrumbNav,
      DynamicMessageBar,
      VueTableLite
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const campaign_id = ref(null);
      const campaignData = ref(null);
      const userNotes = ref('');
      const adminNotes = ref('');
      const totalExecutives = ref(0);
      const statusCounts = ref({ noAction: 0, sent: 0, error: 0 });
      const search_list_id = ref(null);
      const loading = ref(true);
      const email_template = ref('');
      const page = ref(1);
        const firm_name = ref('');
        const firm_id = ref('');
        const search_list_name = ref('');

      const store = useStore();
      const isSuperuser = computed(() => store.getters['user/isUserSuperuser']);
      const user_id = computed(() => store.getters['user/getUserId']);
      const searchTerm = ref('');
      const execsFieldMapping = {
        firmId: "firm_id",
        campaignId: "campaign_id",
        searchListId: "search_list_id",
        execId: "exec_id",
        execFirstName: "exec_first_name",
        execLastName: "exec_last_name",
        execEmail: "exec_email",
        execCompany: "exec_company",
        execTitle: "exec_title",
        emailStatus: "email_status",
        emailStatusDesc: "email_status_desc",
        userId: "user_id",  // Assuming frontend field `userId` maps to `user_id`
        created: "created",
        updated: "updated",
        archiveDate: "archive_date",
      };
        const tableLoadingFinish = () => {
          table.isLoading = false;
        };
      const statusCampaignLabels = {
        0: 'Draft',
        1: 'Pause',
        2: 'Pending',
        3: 'Reconsider',
        4: 'Approved',
        5: 'Active',
        6: 'Pause',
        7: 'Sent',
      };
      const { appContext } = getCurrentInstance();
      const appProfileURL = appContext.config.globalProperties.$AppProfileURL;

      const table = reactive({
        isLoading: false,
        rows: [],
        columns: [
            { label: 'ID', field: 'id', sortable: true },
            { label: 'Executive', field: 'execId', sortable: false, display: (row) => `<a href="${appProfileURL}${row.execId}" target="_blank">Executive</a>`  },
            { label: 'First', field: 'execFirstName', sortable: true },
            { label: 'Last', field: 'execLastName', sortable: true },
            { label: 'Email', field: 'execEmail', sortable: false },
            { label: 'Title', field: 'execTitle', sortable: true },
            { label: 'Company', field: 'execCompany', sortable: false },
            { label: 'Status', field: 'emailStatus', sortable: true, display: (row) => getStatusLabel(row.emailStatus) },
            { label: 'Desc', field: 'emailStatusDesc', sortable: false},
        ],
        totalRecordCount: 0,
        pageSize:0,
        sortable: {
            order: 'exec_last_name',
            sort: 'asc',
        },
        });
      
        const getStatusLabel = (status) => {
            const statusExecutiveLabels = {
                0: 'Pending',
                1: 'Sent',
                2: 'Error'
            };
            return statusExecutiveLabels[status] || 'Pending';
        };
      const execQueryVariables = ref({
        campaign_id: campaign_id.value,
        user_id: user_id.value,
        page: page.value,
        archive: true,
        searchTerm: searchTerm.value,
        });
        const { data: execData, error: execError, executeQuery: fetchExecData } = useQuery({
        query: GET_BULK_EMAIL_EXC_GET_ALL_EXECS_SEARCH_LIST,
        variables: execQueryVariables.value,
        pause: true,
        });
        // Fetch and load table data
        const loadTableData = () => {
            table.isLoading = true;
            execQueryVariables.value.campaign_id = campaign_id.value;
            fetchExecData(execQueryVariables).then(() => {
                table.isLoading = false;
            });
        };
        watchEffect(() => {
        if (execError.value) {
            console.error('Error fetching exec data:', execError.value);
            router.push({ name: 'AppBulkEmailsExecs' }); 
        }
        });
        
        watchEffect(() => {
          if (execData.value) {
              const execResults = execData.value.getBulkEmailExcGetAllExecsSearchList;
              if (execResults === null) {
              console.log("No record found from executive data.");
              } else {
              const { items, totalCount, pageSize, firmId, firmName, searchListName, campaignId } = execResults;
              table.rows = items;
              table.totalRecordCount = totalCount;
              table.pageSize = pageSize;
              firm_name.value = firmName;
              firm_id.value = firmId;
              search_list_name.value = searchListName;
              campaign_id.value = campaignId;
              }
          }
          if (execError.value) {
              console.error('GraphQL Error:', execError.value);
              table.isLoading = false;
          }
        });

        
        const doSearch = (offset, limit, order, sort) => {
          //console.log("DO SEARCH...");
          const backendOrderField = execsFieldMapping[order] || order;
          execQueryVariables.value.offset = offset;
          execQueryVariables.value.limit = limit;
          execQueryVariables.value.sort = sort;
          execQueryVariables.value.order = backendOrderField;
          execQueryVariables.value.searchTerm = searchTerm.value;
          loadTableData();
        };
        watchEffect(() => {
            doSearch(0, table.pageSize, table.sortable.order, table.sortable.sort);
        })
  
      const campaignQueryVariables = ref({ campaignId: campaign_id.value, archive: true });
      const { data: campaign_data, executeQuery: executeCampaignQuery } = useQuery({
        query: GET_BULK_EMAIL_CAMPAIGN_BY_ID,
        variables: campaignQueryVariables.value,
        pause: true,
      });

      // Load Campaign Data
      onMounted(() => {
        campaign_id.value = parseInt(route.params.stat_id, 10);
        loadTableData();
        campaignQueryVariables.value.campaignId = campaign_id.value;
        executeCampaignQuery({ requestPolicy: 'network-only' });
      });
      
  
      // Update Breadcrumbs
      const breadcrumbs = ref([]);
      watchEffect(() => {
        if (search_list_id.value) {
          breadcrumbs.value = [
            { text: 'Home', routerName: 'HomePage' },
            { text: 'Campaign Lists', routerName: 'AppBulkEmailsCampaignsList', params: { status: 'Sent' }},
            { text: 'Campaign Sent' },
          ];
        }
      });
  
      // Handle campaign data response
      watchEffect(() => {
        if (campaign_data.value) {
          const campaign = campaign_data.value.getBulkEmailExcCampaignById;
          if (campaign) {
            campaignData.value = campaign;
            totalExecutives.value = campaign.totalExecutives || 0;
            statusCounts.value = {
              noAction: campaign.noActionYet || 0,
              sent: campaign.sent || 0,
              error: campaign.error || 0,
            };
            userNotes.value = campaign.userNotes || '';
            adminNotes.value = campaign.adminNotes || '';
            email_template.value = campaign.emailTemplate || '';
            const headerTitle = document.getElementById('header-title');
            if (headerTitle) {
                headerTitle.innerText = campaign.name;
            }
            search_list_id.value = campaign.searchListId;
            loading.value = false;
          } else {
            //router.push({ name: 'AppBulkEmailsExecs' });
          }
        }
      });
  
      const goBackPage = () => {
        router.push({ name: 'AppBulkEmailsCampaignsList', params: { status: 'Sent' } });
      };
  
      return {
        campaignData,
        formatDateTime,
        goBackPage,
        userNotes,
        adminNotes,
        isSuperuser,
        totalExecutives,
        statusCounts,
        breadcrumbs,
        statusCampaignLabels,
        loading,
        search_list_id,
        email_template,
        table,
        searchTerm,
        doSearch,
        tableLoadingFinish
      };
    },
  };
  </script>