// Industry hierarchy structure
const industryHierarchy = {
    "Business Services": {
        "Commercial Services": [
            "Facility Services",
            "Field & Route-Based Services",
            "Fire & Security",
            "Logistics & Supply Chain Services"
        ],
        "Distribution": [],
        "Education & Training": [],
        "Government Services": [],
        "Info & Tech-Enabled Services": [
            "Advertising & Marketing Services",
            "Data & Information Services"
        ],
        "Professional Services": [
            "Architecture & Design",
            "Audit & Accounting",
            "Consulting",
            "Engineering & Construction",
            "IT Services",
            "Legal, Regulatory & Compliance",
            "Recruiting, Staffing & Talent Management"
        ],
        "Transportation": []
    },
    "Consumer": {
        "Consumer Products": [
            "Apparel, Footwear & Accessories",
            "Automotive Aftermarket",
            "Beauty & Personal Care",
            "Fitness, Health & Wellness Products",
            "Household Products",
            "Infant, Juvenile & Toys",
            "Outdoor, Enthusiast & Lifestyle Products",
            "Pet Products"
        ],
        "Consumer Services": [
            "Fitness, Health & Wellness Services",
            "Pet Services",
            "Residential & Home Services",
            "Travel, Leisure, Hospitality & Entertainment"
        ],
        "E-commerce / Direct-to-Consumer": [],
        "Food, Beverage & Agricultural Products": [],
        "Restaurants": [],
        "Traditional Retail / Physical Retail": []
    },
    // Add the remaining sectors...
};

// Helper function to get all children for a given parent sector
const getChildrenForSectors = (parentSectors) => {
    let allChildren = [];

    parentSectors.forEach((parent) => {
        Object.keys(industryHierarchy).forEach((topLevelSector) => {
            const subSectors = industryHierarchy[topLevelSector];
            if (subSectors[parent]) {
                allChildren = allChildren.concat(subSectors[parent]);
            }
        });
    });

    return allChildren;
};

// Function to compress industry sectors
export const compressProfileIndustrySector = (profileIndustryData, searchIndustrySectorsCompressed) => {
    // If searchIndustrySectorsCompressed is empty, return the original profileIndustryData
    if (!searchIndustrySectorsCompressed || searchIndustrySectorsCompressed.length === 0) {
        return profileIndustryData;
    }

    let allSectorsCombined = [];

    // Step 1: Loop through searchIndustrySectorsCompressed and get all children or just the item itself
    searchIndustrySectorsCompressed.forEach((compressedSector) => {
        const children = getChildrenForSectors([compressedSector]);

        if (children.length === 0) {
            allSectorsCombined.push(compressedSector);
        } else {
            allSectorsCombined = allSectorsCombined.concat(children);
        }
    });

    // Step 2: Loop through profileIndustryData and match with allSectorsCombined
    const filteredIndustry = profileIndustryData.filter((sector) => allSectorsCombined.includes(sector));

    return filteredIndustry;
};

export default {
    compressProfileIndustrySector
};