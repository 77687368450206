<template>
  <header>
    <h1 class="h3" id="header-title">{{ title }}</h1>
    <!-- Add more header content here -->
  </header>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>