<template>
    <div class="container">
      <BreadcrumbNav :crumbs="breadcrumbs" />
      <DynamicMessageBar
        v-if="!loading"
        label="User Notes"
        :content="userNotes"
        alertClass="alert-info"
        :showEditLink="true"
        @saveMessageBarContent="saveMessageBarContent('userNotes', $event)"
      />
      <DynamicMessageBar
        v-if="!loading"
        label="Admin Notes"
        :content="adminNotes"
        alertClass="alert-warning"
        :showEditLink="isSuperuser"
        @saveMessageBarContent="saveMessageBarContent('adminNotes', $event)"
      />
      <div v-if="campaignData">

        <!-- Campaign Details Card -->
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h5 class="card-title">Campaign ({{ statusCampaignLabels[campaignData.active] }})</h5>
          </div>
          <div class="card-body">
            <p>Created: {{ formatDateTime(campaignData.created) }}</p>
            <p>Ended: {{ campaignData.ended || 'N/A' }}</p>
            <p>Search List ID: {{ campaignData.searchListId }}</p>
            <button class="btn btn-secondary me-2" @click="showEmailTemplate = true">Email Template</button>
            <p v-if="['Pause','Active'].includes(statusCampaignLabels[campaignData.active])"><strong class="text-danger">Note:</strong> Changes made to the email template will apply to the remaining executives in the queue.</p>
          </div>
        </div>

        <!-- Executives Status Card -->
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h5 class="card-title">Executives({{ totalExecutives }})</h5>
          </div>
          <div class="card-body">
            <div class="row text-center">
              <div class="col"><strong>No Action:</strong> {{ statusCounts.noAction }}</div>
              <div class="col"><strong>Sent:</strong> {{ statusCounts.sent }}</div>
              <div class="col"><strong>Error:</strong> {{ statusCounts.error }}</div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div>
            <button class="btn btn-secondary" @click="goBackPage">Back</button>
          </div>
          <div>
            <button
              v-if="['Reconsider', 'Approved'].includes(statusCampaignLabels[campaignData.active])"
              class="btn btn-primary mx-auto"
              @click="updateBulkEmailCampaignStatus('Active')"
            >Schedule</button>
            &nbsp; <button
              v-if="statusCampaignLabels[campaignData.active] === 'Draft' || statusCampaignLabels[campaignData.active] === 'Reconsider'"
              class="btn btn-warning mx-auto"
              @click="updateBulkEmailCampaignStatus('Pending')"
            >Send for Approval</button>
            
            &nbsp; <button
              v-if="statusCampaignLabels[campaignData.active] === 'Pending' && isSuperuser"
              class="btn btn-warning mx-auto"
              @click="updateBulkEmailCampaignStatus('Approved')"
            >Approved</button>
            &nbsp; <button
              v-else-if="statusCampaignLabels[campaignData.active] === 'Pending'"
              class="btn btn-warning mx-auto"
              @click="updateBulkEmailCampaignStatus('Draft')"
            >Move to Draft</button>

            &nbsp; <button
              v-if="statusCampaignLabels[campaignData.active] === 'Pending' || statusCampaignLabels[campaignData.active] === 'Approved' && isSuperuser"
              class="btn btn-warning mx-auto"
              @click="updateBulkEmailCampaignStatus('Reconsider')"
            >Reconsider</button>

            &nbsp; <button
              v-if="statusCampaignLabels[campaignData.active] === 'Active'"
              class="btn btn-warning mx-auto"
              @click="updateBulkEmailCampaignStatus('Pause')"
            >Pause</button>

            &nbsp; <button
              v-if="statusCampaignLabels[campaignData.active] === 'Pause'"
              class="btn btn-warning mx-auto"
              @click="updateBulkEmailCampaignStatus('Active')"
            >Active</button>

            
          </div>
          <div>
            <button 
              v-if="['Draft','Pending','Reconsider', 'Approved'].includes(statusCampaignLabels[campaignData.active])"
              class="btn btn-danger" @click="deleteCampaign">Delete</button>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Loading campaign data...</p>
      </div>
  
      <!-- Confirmation Modals -->
      <VueFinalModal
        v-model="showConfirmSchedule"
        classes="modal-container"
        content-class="modal-dialog modal-dialog-centered"
        :show-close-button="false"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Schedule</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to schedule this campaign?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="scheduleCampaign">Yes</button>
            <button type="button" class="btn btn-secondary" @click="closeModal">No</button>
          </div>
        </div>
      </VueFinalModal>
  
      
      <BulkEmailExecTemplateModal
        :showModal="showEmailTemplate"
        :searchListId="search_list_id"
        :pageTitle="page_title"
        :firmId="firm_id"
        :userId="user_id"
        @dbStatusUpdate="dbStatusUpdate"
        @update:showModal="showEmailTemplate = $event"
      />
    </div>
  </template>
  
  <script>
  import { ref, watchEffect, onMounted, computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useQuery, useMutation } from '@urql/vue';
  import { GET_BULK_EMAIL_CAMPAIGN_BY_ID, UPDATE_BULK_EMAILS_CAMPAIGN_NOTES, DELETE_BULK_EMAIL_CAMPAIGN } from '../graphql/bulk_emails_to_execs';
  import { formatDateTime } from '@/components/DateFormatter';
  import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
  import DynamicMessageBar from '@/components/DynamicMessageBar.vue';
  import { useStore } from 'vuex';
  import BulkEmailExecTemplateModal from '@/components/BulkEmailExecTemplateModal.vue';
  import { VueFinalModal } from 'vue-final-modal';
  import { useConfirmDialog } from '@/plugins/useConfirmDialog';
  
  export default {
    name: 'AppBulkEmailsCampaign',
    components: {
      BreadcrumbNav,
      DynamicMessageBar,
      BulkEmailExecTemplateModal,
      VueFinalModal
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const campaign_id = ref(null);
      const campaignData = ref(null);
      const showConfirmSchedule = ref(false);
      
      const userNotes = ref('');
      const adminNotes = ref('');
      const campaignStatus = ref('');
      
      const totalExecutives = ref(0);
      const statusCounts = ref({ noAction: 0, sent: 0, error: 0 });
      const search_list_id = ref(null);
      const page_title = ref(null);
      const loading = ref(true);
      
      const store = useStore()
      const user_id = computed(() => store.getters['user/getUserId']);
      const isSuperuser = computed(() => store.getters['user/isUserSuperuser']);

      //console.log("user_id  sd:::",user_id.value);

      const showEmailTemplate = ref(false);
      const firm_id = ref('');
      const emailTemplateFound = ref(false);
      
      const statusCampaignLabels = {
        0: 'Draft',
        1: 'Pause',
        2: 'Pending',
        3: 'Reconsider',
        4: 'Approved',
        5: 'Active',
        6: 'Pause',
        7: 'Sent',
      };
      const confirmDialog = useConfirmDialog();

      // URQL Queries and Mutations
      const campaignQueryVariables = ref({ campaignId: campaign_id.value });
      const { data: campaign_data, executeQuery: executeCampaignQuery } = useQuery({
        query: GET_BULK_EMAIL_CAMPAIGN_BY_ID,
        variables: campaignQueryVariables.value,
        pause: true,
      });
  
      const { executeMutation: executeUpdateCampaignData } = useMutation(UPDATE_BULK_EMAILS_CAMPAIGN_NOTES);
      // Load Campaign Data
      onMounted(() => {
        campaign_id.value = parseInt(route.params.campaign_id, 10);
        const storedStatus = JSON.parse(localStorage.getItem('db_status'));
        if (storedStatus !== null) {
          emailTemplateFound.value = storedStatus;
        }
      });
  
      watchEffect(() => {
        if (campaign_id.value) {
          campaignQueryVariables.value.campaignId = campaign_id.value;
          executeCampaignQuery({requestPolicy: 'network-only'});
        }
      });
  
      // Update Breadcrumbs
      const breadcrumbs = ref([]);
      watchEffect(() => {
        if (search_list_id.value) {
            breadcrumbs.value = [
                { text: 'Home', routerName: 'HomePage' },
                { text: 'Bulk Emails', routerName: 'AppBulkEmailsExecs' },
                {
                    text: 'Executives',
                    routerName: 'AppBulkEmailsExecsList', params: { list_id: search_list_id.value } 
                },
                { text: 'Schedule a Campaign' }
            ];
        }
    });
  
      // Handle campaign data response
      watchEffect(() => {
        if (campaign_data.value) {
          const campaign = campaign_data.value.getBulkEmailExcCampaignById;
          if (campaign) {
            campaignData.value = campaign;
            totalExecutives.value = campaign.totalExecutives || 0;
            statusCounts.value = {
              noAction: campaign.noActionYet || 0,
              sent: campaign.sent || 0,
              error: campaign.error || 0,
            };
            userNotes.value = campaign.userNotes || '';
            adminNotes.value = campaign.adminNotes || '';
            const headerTitle = document.getElementById('header-title');
            if (headerTitle) {
                headerTitle.innerText = campaign.name;
            }
            page_title.value = campaign.name;
            search_list_id.value = campaign.searchListId;
            firm_id.value = campaign.firmId;
            loading.value = false;
          } else {
            router.push({ name: 'AppBulkEmailsExecs' });
          }
        }
      });
  
      const updateBulkEmailCampaignStatus = async (newStatus) => {
        campaignStatus.value = newStatus;
        await saveUpdateCampaignData();
        router.push({ name: 'AppBulkEmailsCampaignsList', params: { status: newStatus } });
      };
      const saveUpdateCampaignData = async () => {
        const variables = {
          id: campaign_id.value || null,
          adminNotes: adminNotes.value || null,
          userNotes: userNotes.value || null,
          status: campaignStatus.value || null,
        };
        try {
          await executeUpdateCampaignData(variables);
          //console.log('Save/update result:', result);
        } catch (error) {
          console.error('Error updating campaign notes:', error);
        }
      };
  
      // Save Message Bar Content Handler
      const saveMessageBarContent = (type, newContent) => {
        if (type === 'userNotes') userNotes.value = newContent;
        if (type === 'adminNotes') adminNotes.value = newContent;
        saveUpdateCampaignData(); // Save immediately after content change
      };
  
      // Campaign Actions
      const confirmScheduleNow = () => {
        showConfirmSchedule.value = true;
      };
  
  
      const { executeMutation: executeDeleteCampaign } = useMutation(DELETE_BULK_EMAIL_CAMPAIGN);
      const deleteCampaign = async () => {
        
        const confirmed = await confirmDialog(`Are you sure you want to delete this campaign? This will remove all executives and stats.`);
        if (!confirmed) return;
        try {
          const variables = { id: campaign_id.value };
          const result = await executeDeleteCampaign(variables);
          if (result.data?.deleteBulkEmailCampaign) {
            console.log('Campaign deleted successfully');
            router.push({ name: 'AppBulkEmailsExecs' });
          } else {
            alert('Failed to delete campaign');
          }
        } catch (error) {
          alert('Error deleting campaign:', error);
        }
        closeModal();
      };
  
      const closeModal = () => {
        showConfirmSchedule.value = false;
      };
  
      const goBackPage = () => {
        router.push({ name: 'AppBulkEmailsExecsList', params: { list_id: search_list_id.value } });
      };

      
      const dbStatusUpdate = (data) => {
        emailTemplateFound.value = data;
      };
      return {
        campaignData,
        formatDateTime,
        goBackPage,
        userNotes,
        adminNotes,
        campaignStatus,
        isSuperuser,
        saveMessageBarContent,
        saveUpdateCampaignData,
        closeModal,
        totalExecutives,
        statusCounts,
        breadcrumbs,
        statusCampaignLabels,
        confirmScheduleNow,
        deleteCampaign,
        showConfirmSchedule,
        loading,
        user_id,
        showEmailTemplate,
        page_title,
        firm_id,
        emailTemplateFound,
        dbStatusUpdate,
        search_list_id,
        updateBulkEmailCampaignStatus
      };
    },
  };
</script>  