<template>
    <div class="alert d-flex justify-content-between align-items-center mb-0" :class="alertClass">
      <div>
        <strong>{{ label }}: </strong>
        <span v-if="content">{{ content }}</span>
        <span v-else class="text-muted">No {{ label.toLowerCase() }} available.</span>
      </div>
  
      <!-- Show Add/Edit link based on the showEditLink property -->
      <a v-if="showEditLink && !readonly" href="#" @click.prevent="showModal = true">{{ content ? 'Edit' : 'Add' }}</a>
  
      <!-- Dynamic Modal -->
      <VueFinalModal v-model="showModal" content-class="custom-modal-content wider-modal w-75">
        <button class="btn-close" @click="showModal = false"></button>
        <h3>{{ label }}</h3>
        <textarea v-model="contentCopy" :placeholder="'Write ' + label.toLowerCase() + ' here...'" rows="5" class="form-control"></textarea>
        <button v-if="!readonly" class="btn btn-primary mt-2" @click="saveContent">Save</button>
      </VueFinalModal>
    </div>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  import { VueFinalModal } from 'vue-final-modal';
  
  export default {
    name: 'DynamicMessageBar',
    props: {
      label: {
        type: String,
        required: true
      },
      content: {
        type: String,
        default: ''
      },
      alertClass: {
        type: String,
        default: 'alert-info'
      },
      showEditLink: {
        type: Boolean,
        default: true
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      VueFinalModal
    },
    emits: ['saveMessageBarContent'],
    setup(props, { emit }) {
      const showModal = ref(false);
      const contentCopy = ref(props.content);
  
      watch(() => props.content, (newContent) => {
        contentCopy.value = newContent;
      });
  
      const saveContent = () => {
        emit('saveMessageBarContent', contentCopy.value);
        showModal.value = false;
      };
  
      return {
        showModal,
        contentCopy,
        saveContent
      };
    }
  };
  </script>
  