<template>
  <div class="container">
    <BreadcrumbNav :crumbs="breadcrumbs" />
    
    <div class="table-container mt-3">

      <div class="d-flex justify-content-between align-items-center mb-3">
      <!-- Search Term Input on the Left -->
      <div class="d-flex align-items-center">
        <input 
          type="text" 
          v-model="searchTerm" 
          placeholder="Search..." 
          class="form-control me-2" 
          style="max-width: 300px;"
        />
      </div>
    </div>

      <VueTableLite
        :rows="table.rows"
        :columns="table.columns"
        :is-loading="table.isLoading"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        :page-size="table.pageSize"
        @do-search="doSearch"
        @is-finished="tableLoadingFinish"
        wrapper-class="w-100"
      />

      <!-- No Records Found Message -->
      <div v-if="!table.isLoading && table.rows.length === 0" class="alert alert-warning text-center mt-3">
        <strong>No records found!</strong> Please try adjusting your search.
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useQuery } from '@urql/vue';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import VueTableLite from 'vue3-table-lite';
import { GET_BULK_EMAIL_CAMPAIGNS_BY_STATUS } from '@/graphql/bulk_emails_to_execs';
import { formatDateTime } from '@/components/DateFormatter';

export default {
  name: 'AppBulkEmailsCampaignsList',
  components: {
    BreadcrumbNav,
    VueTableLite,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const status = ref(route.params.status);

    const table = reactive({
      isLoading: false,
      rows: [],
      columns: [
          { label: 'ID', field: 'id', sortable: true },
          { label: 'Name', field: 'name', sortable: true },
          { label: 'Created', field: 'created', sortable: true, display: (row) => formatDateTime(row.created) },
          {
              label: 'Actions',
              field: 'actions',
              display: (row) => (
                  row.active === 7  // Assuming 7 represents the "Sent" status
                  ? `<button type="button" data-id="${row.id}" class="btn btn-secondary view-stats-btn">View Stats</button>`
                  : `<button type="button" data-id="${row.searchListId}" class="btn btn-primary continue-btn">Continue</button>`
              )
          },
      ],
      totalRecordCount: 0,
      pageSize: 0,
      sortable: {
          order: 'name',
          sort: 'asc',
      },
  });
    const continueToList = (listId) => {
      router.push({ name: 'AppBulkEmailsExecsList', params: { list_id: listId } });
    };
    const searchTerm = ref('');
    const campaignFieldMapping = {
      id: 'id',
      name: 'name',
      searchListId: 'search_list_id',
      active: 'active',
      userId: 'user_id',
      fromUser: 'from_user',
      created: 'created',
      ended: 'ended',
    };

    const bulkEmailCampaignQueryVariables = ref({
      status: status.value,
      order: 'name',
      sort: 'asc',
      searchTerm: searchTerm.value,
    });

    const { data: bulkEmailData, error: bulkEmailError, executeQuery: fetchBulkEmailCampaignQuery } = useQuery({
      query: GET_BULK_EMAIL_CAMPAIGNS_BY_STATUS,
      variables: bulkEmailCampaignQueryVariables.value,
      pause: true,
    });

    const fetchCampaigns = () => {
      table.isLoading = true;
      try {
        fetchBulkEmailCampaignQuery({ requestPolicy: 'network-only' });
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    const tableLoadingFinish = () => {
      table.isLoading = false;
    };

    watchEffect(() => {
      if (bulkEmailData.value) {
        const paginationData = bulkEmailData.value.getBulkEmailCampaignsByStatus;

        table.rows = paginationData.items;
        table.totalRecordCount = paginationData.totalCount;
        table.pageSize = paginationData.pageSize;
      }
      table.isLoading = false;
    });

    watchEffect(() => {
      if (bulkEmailError.value) {
        console.error('Error fetching bulk email campaigns:', bulkEmailError.value);
      }
    });

    const doSearch = (offset, limit, order, sort) => {
      const backendOrderField = campaignFieldMapping[order] || order;
      bulkEmailCampaignQueryVariables.value.offset = offset;
      bulkEmailCampaignQueryVariables.value.limit = limit;
      bulkEmailCampaignQueryVariables.value.sort = sort;
      bulkEmailCampaignQueryVariables.value.order = backendOrderField;
      bulkEmailCampaignQueryVariables.value.searchTerm = searchTerm;
      fetchCampaigns();
    };

    onMounted(() => {
      fetchCampaigns();
      document.addEventListener('click', (event) => {
        if (event.target.classList.contains('continue-btn')) {
          const listId = event.target.getAttribute('data-id');
          continueToList(listId);
        }//contineu button
        if (event.target.classList.contains('view-stats-btn')) {
            const statId = event.target.getAttribute('data-id');
            router.push({ name: 'AppBulkEmailsSentViewStat', params: { stat_id: statId } });
        }
        
      });

      watchEffect(() => {
        status.value = route.params.status || 'Draft';
        if (status.value) {
          const headerTitle = document.getElementById('header-title');
          if (headerTitle) {
            headerTitle.innerText = `Bulk Emails to Campaigns - List - ${status.value}`;
          }
        }
        bulkEmailCampaignQueryVariables.value.status = status.value;
        fetchCampaigns();
      });
    });

    

    

    const breadcrumbs = computed(() => [
      { text: 'Home', routerName: 'HomePage'  },
      { text: `Bulk Emails - ${status.value}` },
    ]);

    return {
      breadcrumbs,
      table,
      doSearch,
      tableLoadingFinish,
      searchTerm,
      continueToList
    };
  },
};
</script>

<style scoped>
.table-container {
  margin-top: 20px;
}
</style>
