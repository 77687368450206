<template>
  <footer>
    <p class="footer-text">{{ footerText }}</p>
    <!-- Add more footer content here -->
  </footer>
</template>

<script>
export default {
  name:'FooterApp',
  props: {
    footerText: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
/* Add your footer styles here */
.footer-text{
  padding: 20px 0;
}
</style>
