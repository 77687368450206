import gql from 'graphql-tag';

// Query for sending an email through Zoho
export const SEND_ZOHO_EMAIL_NOW = gql`
  query SendZohoEmailNow(
    $fromAddress: String!,
    $toAddress: String!,
    $token: String!,
    $emailSubject: String!,
    $emailContent: String!,
    $accountId: String!,
    $bccAddress: String,
    $ccAddress: String
  ) {
    sendZohoEmailNow(
      fromAddress: $fromAddress,
      toAddress: $toAddress,
      token: $token,
      emailSubject: $emailSubject,
      emailContent: $emailContent,
      accountId: $accountId,
      bccAddress: $bccAddress,
      ccAddress: $ccAddress
    ) {
      message
    }
  }
`;

export const GET_ZOHO_AUTH_INFO = gql`
  query GetZohoAuthInfo($tokenType: String!, $userId: Int!) {
    getZohoAuthInfo(tokenType: $tokenType, userId: $userId) {
      accessToken
      accountId
      message
    }
  }
`;