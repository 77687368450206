
import { createApp, h, ref } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
import store from './store';
import authentication, { _keycloak } from '@/plugins/authentication';
import axios from 'axios';
import '@/assets/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Add this line
import '@/assets/css/custom.css';
import vuetify from './plugins/vuetify'
import {APP_API_BASE_URL} from './config/project-config';
import { createVfm } from 'vue-final-modal';
import { provideClient } from '@urql/vue';
import urqlClient from './urql';

axios.defaults.baseURL = APP_API_BASE_URL;
axios.interceptors.response.use((response) => response,
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        //Refresh Token
        await store.dispatch('refreshTokenAction');
        //window.location.reload();
        console.log("Refresh Failed...");
      } else {
        // Show a generic error message
        alert('An error occurred. Please try again later.')
      }
    }
    return Promise.reject(error)
  },
);

_keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then(() => {
  const app = createApp({
    setup() {
      provideClient(urqlClient);
    },
    render: () => h(App),
  });
  const head = createHead();
  app.use(router);
  app.use(authentication);
  app.use(store); // Use the Vuex store
  app.use(head);
  app.config.globalProperties.$keycloak = _keycloak;
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$AppProfileURL = 'https://app.ceodiscovery.com/discover/profile/';
  app.config.globalProperties.$AppFirmURL = 'https://app.ceodiscovery.com/discover/firm/';
  app.use(vuetify)
  const vfm = createVfm();
  app.use(vfm);
  //app.use(ClientTable);
  //app.use(ServerTable);
  app.config.globalProperties.$username = _keycloak.tokenParsed.preferred_username;
  app.config.globalProperties.$email = _keycloak.tokenParsed.email;
  app.config.globalProperties.$userid = ref(0);
  app.config.globalProperties.$isSuperuser = ref(0);

  app.mount('#app');
  /* Refresh Token */
  localStorage.setItem("vue-token", _keycloak.token);
  localStorage.setItem("vue-refresh-token", _keycloak.refreshToken);

  // To fetch UserInfo like name, email id etc.,
  _keycloak.loadUserInfo().then(userInfo => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }).catch(error => {
    console.error('Failed to load user info:', error);
  });

  // Every 60 seconds, check if the token expires soon (here 70 seconds used in updateToken), if so, refresh it.
  setInterval(() => {
    // updateToken parameter is the minValidity time
    _keycloak.updateToken(70).then(refreshed => {
      if (refreshed) {
        console.log('Token refreshed:', refreshed);
      } else {
        console.log('Token not refreshed, valid for ' +
          Math.round(_keycloak.tokenParsed.exp + _keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      console.log('Failed to refresh token');
    });
  }, 60000);
  /* End Refresh Token */
}).catch((error) => {
  console.error("Authentication failed", error);
});