<template>
  <div>
    <div class="input-group mb-3">
      <input
        type="text"
        v-model="currentEmail"
        placeholder="Enter email"
        class="form-control"
        @keyup.enter="addEmail"
      />
      <button @click="addEmail" class="btn btn-outline-primary">
        Add Email
      </button>
    </div>

    <div v-if="emails.length" class="mt-3">
      <h5>Email List:</h5>
      <ul class="list-group mb-3">
        <li v-for="(email, index) in emails" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
          {{ email }}
          <button @click="removeEmail(index)" class="btn btn-outline-danger btn-sm">Delete</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    initialEmails: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const emails = ref([...props.initialEmails]);
    const currentEmail = ref('');

    const addEmail = () => {
      const trimmedEmail = currentEmail.value.trim().toLowerCase();
      if (trimmedEmail && isValidEmail(trimmedEmail) && !emails.value.includes(trimmedEmail)) {
        emails.value.push(trimmedEmail);
        currentEmail.value = '';
        updateEmailOutput();
      }
    };

    const removeEmail = (index) => {
      emails.value.splice(index, 1);
      updateEmailOutput();
    };

    const updateEmailOutput = () => {
      const emailOutput = emails.value.join(', ');
      emit('update-emails', emailOutput);
    };

    const isValidEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
    

    return {
      emails,
      currentEmail,
      addEmail,
      removeEmail
    };
  }
};
</script>
