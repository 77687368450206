import gql from 'graphql-tag';

export const GET_WEEKLY_EMAILS_SAVED_LIST = gql`
  query GetWeeklyEmailsSavedLists(
    $limit: Int, 
    $offset: Int, 
    $userId: Int!, 
    $isSuperuser: Int!, 
    $status: String!,
    $order: String,
    $sort: String,
    $searchTerm: String
  ) {
    listWeeklyEmailsSavedLists(
      limit: $limit, 
      offset: $offset, 
      userId: $userId, 
      isSuperuser: $isSuperuser, 
      status: $status,
      order: $order,
      sort: $sort,
      searchTerm: $searchTerm
    ) {
      hasNext
      hasPrevious
      records {
        content
        created
        dateRange
        duplicateContent
        emailStatus
        firmId
        id
        listname
        notesFromAdmin
        notesFromUser
        professionalId
        savedBy
        status
        statusDate
        updated
      }
      totalCount
    }
  }
`;

export const GET_WEEKLY_EMAILS_SAVED_LIST_BY_ID = gql`
  query GetWeeklyEmailsSavedListById($id: Int!, $userId: Int!, $isSuperuser: Int!) {
    getListWeeklyEmailsFromSavedLists(id: $id, userId: $userId, isSuperuser: $isSuperuser) {
      content
      created
      dateRange
      duplicateContent
      emailStatus
      firmId
      firmName
      id
      listname
      notesFromAdmin
      notesFromUser
      professionalId
      professionalName
      professionalEmail
      savedBy
      status
      statusDate
      updated
      emailHeaderText
      emailFooterText
      ccAddress
    }
  }
`;



export const CREATE_OR_UPDATE_WEEKLY_EMAIL_SAVED_LIST = gql`
  mutation CreateOrUpdateWeeklyEmailSavedList(
    $id: ID,
    $listname: String,
    $firmId: String,
    $professionalId: String,
    $dateRange: String,
    $content: String,
    $duplicateContent: String,
    $savedBy: ID,
    $emailStatus: Int,
    $status: String,
    $notesFromAdmin: String,
    $notesFromUser: String,
    $emailHeaderText: String,
    $emailFooterText: String,
    $ccAddress: String
  ) {
    createOrUpdateWeeklyEmailSavedList(
      id: $id,
      listname: $listname,
      firmId: $firmId,
      professionalId: $professionalId,
      dateRange: $dateRange,
      content: $content,
      duplicateContent: $duplicateContent,
      savedBy: $savedBy,
      emailStatus: $emailStatus,
      status: $status,
      notesFromAdmin: $notesFromAdmin,
      notesFromUser: $notesFromUser,
      emailHeaderText: $emailHeaderText,
      emailFooterText: $emailFooterText,
      ccAddress: $ccAddress
    ) {
      record {
        id
        listname
        firmId
        professionalId
        dateRange
        content
        duplicateContent
        savedBy
        emailStatus
        status
        statusDate
        notesFromAdmin
        notesFromUser
        emailHeaderText
        emailFooterText
        ccAddress
        created
        updated
      }
      message
    }
  }
`;

export const DELETE_WEEKLY_EMAIL_SAVED_LIST = gql`
  mutation DeleteWeeklyEmailSavedList($id: ID!, $userId: ID!) {
    deleteWeeklyEmailSavedList(id: $id, userId: $userId) {
      success
      message
    }
  }
`;

export const COUNT_WEEKLY_EMAILS_BY_STATUS = gql`
  query CountWeeklyEmailsByStatus(
    $userId: Int!,
    $isSuperuser: Int!,
    $statuses: [String!]!
  ) {
    countWeeklyEmailsByStatus(
      userId: $userId,
      isSuperuser: $isSuperuser,
      statuses: $statuses
    ) {
      count
      status
    }
  }
`;

export const WEEKLY_EMAILS_LISTS_BY_PROFESSIONAL_IDS_DATES = gql`
  query WeeklyEmailsListsByProfessionalIdsDates(
    $enddate: String!,
    $professionalIds: [String!]!,
    $startdate: String!,
    $userId: Int!
  ) {
    weeklyEmailsListsByProfessionalIdsDates(
      enddate: $enddate,
      professionalIds: $professionalIds,
      startdate: $startdate,
      userId: $userId
    ) {
      hasNext
      hasPrevious
      records {
        firmId
        savedBy
        status
        created
        id
        professionalId
      }
    }
  }
`;

export const WEEKLY_EMAILS_LATEST_BY_PROFESSIONAL_IDS = gql`
  query WeeklyEmailsLatestByProfessionalIds(
    $professionalIds: [String!]!,
    $userId: Int!
  ) {
    weeklyEmailsLatestByProfessionalIds(
      professionalIds: $professionalIds,
      userId: $userId
    ) {
      hasNext
      hasPrevious
      records {
        id
        listname
        firmId
        professionalId
        dateRange
        content
        duplicateContent
        savedBy
        emailStatus
        status
        notesFromAdmin
        notesFromUser
        emailHeaderText
        emailFooterText
        statusDate
        created
        updated
      }
    }
  }
`;